import ls from "localstorage-slim";

import encUTF8 from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";

import { getEnvs } from "./getEnvs";

const { secret }: any = getEnvs();

export const configEnc = () => {
  ls.config.encrypt = true;
  ls.config.secret = secret;

  ls.config.encrypter = (data: any, secret: any) =>
    AES.encrypt(JSON.stringify(data), secret).toString();

  ls.config.decrypter = (data: any, secret: any) => {
    try {
      return JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
    } catch (e) {
      return data;
    }
  };
};

export const encDecrypt = (function () {
  const encrypt = (arg: string) => AES.encrypt(arg, secret).toString();
  const decrypt = (arg: string) => AES.decrypt(arg, secret).toString(encUTF8);

  return { encrypt, decrypt };
})();
