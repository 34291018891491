import React from "react";

import ReactDOM from "react-dom";

import { IModal } from "../../../models";

import { motion, AnimatePresence } from "framer-motion";

import styles from "./index.module.scss";
import CloseIconSvg from "../../svgs/CloseIconSvg";

const Modal = ({ show, setShow, columnLayout, children }: IModal) => {
  const handleShow = (e: any) => {
    if (e.target.dataset.modalWrapper || e.target.dataset.modalBody) {
      setShow(false);
    }
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      {show && (
        <motion.section
          className={`position-absolute d-flex align-items-center justify-content-center ${styles.wrapper} w-100`}
          data-modal-wrapper="true"
          onClick={handleShow}
        >
          <div
            className={`${columnLayout} container-fluid position-relative d-flex align-items-center justify-content-center`}
            onClick={handleShow}
            data-modal-body="true"
          >
            <div
              className={`position-absolute d-flex align-items-center justify-content-center ${styles.icon}`}
              onClick={() => setShow(false)}
            >
              <CloseIconSvg width="17" height="17" viewBox="0 0 25 25" />
            </div>

            {children}
          </div>
        </motion.section>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Modal;
