import React from "react";
import { IPopUpPosition } from "../../../models";

const PopUpPosition = ({
  x,
  y,
  width,
  styleClass,
  children,
}: IPopUpPosition) => {
  return (
    <div
      className={`position-absolute ${styleClass}`}
      style={{ top: y, left: x, width }}
    >
      {children}
    </div>
  );
};

export default React.memo(PopUpPosition);
