import React from "react";

import { Navigate } from "react-router-dom";

import { useAuthenticate } from "./../../hooks/useAuthenticate";

const Public: React.FC = ({ children }) => {
  const isAuthenticated = useAuthenticate();

  return <>{!isAuthenticated ? children : <Navigate to="/terminal" />}</>;
};

export default Public;
