import React from "react";

import PaymentOptions from "./payment-option";
import PaymentSummary from "./payment-summary";

const PaymentComponent = () => {
  return (
    <section className="container-fluid" style={{ height: "91.2vh" }}>
      <section className="row h-100">
        <PaymentSummary />
        <PaymentOptions />
      </section>
    </section>
  );
};

export default PaymentComponent;
