import React from "react";

const ListToggleIconSvg = () => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 30 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.22223 2H28"
        stroke="#7F5297"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.22223 11.5H28"
        stroke="#7F5297"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.22223 21H28"
        stroke="#7F5297"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 2H2.015"
        stroke="#7F5297"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 11.5H2.015"
        stroke="#7F5297"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 21H2.015"
        stroke="#7F5297"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ListToggleIconSvg;
