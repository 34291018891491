import React from "react";

import { IinputField } from "../../../models";
import { inputVariant } from "../../../utils/anims";

import styles from "./index.module.scss";

import { motion } from "framer-motion";
import ErrorIconSvg from "./../../svgs/ErrorIconSvg";

const renderError = (error: string, showIcon: boolean | undefined) => {
  return (
    <div
      className={`small text-danger px-1 d-flex align-items-center ${
        showIcon && "justify-content-end"
      }`}
    >
      {showIcon && (
        <div className="mx-2">
          <ErrorIconSvg />
        </div>
      )}
      {error}
    </div>
  );
};

export const InputField = ({
  label,
  id,
  error,
  className,
  showIcon,
  ...props
}: IinputField) => {
  return (
    <motion.section variants={inputVariant} className={styles.wrapper}>
      <div className="text-capitalize">
        {label && <label htmlFor={id}>{label}</label>}
      </div>
      <input
        className={`${styles.inputBox} ${className ? className : ""} ${
          error && props.touched ? styles.error && styles.error : ""
        }`}
        {...props}
      />
      <aside>{error && props.touched && renderError(error, showIcon)}</aside>
    </motion.section>
  );
};

export const PasswordField = ({
  label,
  password,
  id,
  error,
  className,
  handleShowPassword,
  showIcon,
  ...props
}: IinputField) => {
  return (
    <motion.section variants={inputVariant} className={styles.wrapper}>
      <div className="text-capitalize">
        {label && <label htmlFor={id}>{label}</label>}
      </div>
      <div className="d-flex align-items-center">
        <input className={className ? className : styles.password} {...props} />
        <span className={styles.toggle} onClick={() => handleShowPassword()}>
          {props.type === "password" ? "Show" : "Hide"}
        </span>
      </div>
      <aside>{error && props.touched && renderError(error, showIcon)}</aside>
    </motion.section>
  );
};
