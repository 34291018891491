import PouchDB from "pouchdb";

import pouchAuth from "pouchdb-authentication";

import pouchUpsert from "pouchdb-upsert";

const plugins = () => {
  PouchDB.plugin(pouchAuth);
  PouchDB.plugin(pouchUpsert);
};

export default plugins;
