import React from "react";

import SideMenu from "./../side-menu";

import Navbar from "../navbar";

import { ELocalDBNames } from "../../models";

import { useReplicator } from "./../../hooks/useReplicator";
import { useDBChanges } from "./../../hooks/useDBChanges";

import { optsProducts } from "./../../integrations/pouch/opts";
import { productChangesqClient } from "./../../integrations/react_query/queryClients";

import { productFilter } from "./../../integrations/pouch/opts";

import { useAppSelector } from "./../../store/storeConfig";

interface INavLayout {
  children: React.ReactNode;
  showBar: boolean;
}

const NavLayout = ({ children, showBar }: INavLayout) => {
  const [navState, setNavState] = React.useState(false);

  const { isProductReady } = useAppSelector((state) => state.productSlice);

  const { isReplicating: isReplicatingProduct } = useReplicator({
    localDBName: ELocalDBNames.product,
    filter: productFilter(),
    filterFunc: optsProducts,
  });

  useDBChanges({
    localDBName: ELocalDBNames.product,
    qClient: productChangesqClient,
    shouldBeginChange: isProductReady,
  });

  console.log(isReplicatingProduct, "replicating products man 0000000");
  //console.log("is it replicating", isReplicatingProduct);
  //const [isReplicatingCart] = useReplicator({ localDBName: "carts" });
  //const [isReplicatingTransaction] = useReplicator({
  //  localDBName: "transactions",
  //});

  return (
    <>
      <Navbar {...{ navState, setNavState, showBar }} />
      <SideMenu {...{ navState, setNavState }} />
      {/*{isReplicatingProduct && (
        <div
          className="position-absolute w-100 bg-dark d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <div className="text-white">I am just replicating man</div>
        </div>
      )}*/}
      {children}
    </>
  );
};

export default NavLayout;
