import React from "react";

import styles from "./index.module.scss";

type TButtonIconText = {
  children: React.ReactNode;
  [props: string]: any;
};

const ButtonIconText = ({ children, ...props }: TButtonIconText) => {
  return (
    <button
      {...props}
      className={`${styles.button} d-flex align-items-center justify-content-start p-0`}
    >
      {children}
    </button>
  );
};

export default ButtonIconText;
