import React from "react";

const ArrowDown = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.61124 0.0166016L4.93379 3.58574L8.25634 0.0166016L9.58535 0.73043L4.93379 5.72723L0.282227 0.73043L1.61124 0.0166016Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export default ArrowDown;
