import React from "react";

import { cartData } from "../../../../data/cart";

import Accordion from "../../../resuable-ui/accordion";
import DeleteIconSvg from "../../../svgs/DeleteIconSvg";
import RightArrowSmSvg from "../../../svgs/RightArrowSmSvg";

import { formatAmount } from "../../../../utils/funcs";

import styles from "./index.module.scss";

import Modal from "../../../resuable-ui/modal";
import { DeleteModalContent } from "./../record-modals/delete/index";

type THeader = {
  quantity: number;
  name: string;
  amount: number;
  setExpanded: (arg: any) => void;
  id: number;
  expanded: any;
  setShow: (arg: boolean) => void;
  setItem: (arg: { name: string; id: number }) => void;
};

const renderHeader = ({
  quantity,
  name,
  amount,
  expanded,
  setExpanded,
  id,
  setShow,
  setItem,
}: THeader) => {
  const isOpen = id === expanded;

  const handleExpand = (e: any) => {
    if (!(e.target.id === "delete-icon")) {
      setExpanded(isOpen ? false : id);
    }
  };
  return (
    <section
      className="w-100 d-flex align-items-center justify-content-between"
      onClick={handleExpand}
    >
      <div className="d-flex align-items-center">
        <div className={isOpen ? styles.rotate : styles.transit}>
          <RightArrowSmSvg />
        </div>

        <div className="mx-2 mx-lg-3 mx-xl-3 mx-xxl-3">{quantity}</div>
        <div>{name}</div>
      </div>
      <div className="d-flex align-items-center">
        <div className="mx-1 mx-md-3 mx-lg-3 mx-xl-4 mx-xxl-4">
          {formatAmount(amount)}
        </div>
        <div
          id="delete-icon"
          onClick={() => {
            setShow(true);
            setItem({ name, id });
          }}
        >
          <DeleteIconSvg id="delete-icon" />
        </div>
      </div>
    </section>
  );
};

type TBody = {
  quantity: number;
  name: string;
  amount: number;
};

const renderBody = ({ quantity, name, amount }: TBody) => {
  return (
    <section className={`${styles.bodyWrapper}`}>
      <div
        className={`${styles.tableBodyHeader} d-flex align-items-center justify-content-between`}
      >
        <div>Quantity</div>
        <div>Price (&#8358;)</div>
        <div>Discount (&#8358;)</div>
      </div>

      <div
        className={`d-flex align-items-center justify-content-between ${styles.tableBodyWrapper}`}
      >
        <div>
          <input value={quantity} />
        </div>
        <div>{formatAmount(amount)}</div>
        <div>{formatAmount(0)}</div>
      </div>
    </section>
  );
};

export const CartItems = () => {
  const [expanded, setExpanded] = React.useState<false | number>(0);

  const [show, setShow] = React.useState(false);

  const [item, setItem] = React.useState<{ name: string; id: number }>({
    name: "this is the default name man",
    id: 2,
  });

  return (
    <>
      <Modal
        {...{
          show,
          setShow,
          columnLayout: "col-9 col-lg-8 col-xl-7 col-xxl-6",
        }}
      >
        <DeleteModalContent {...{ item, setShow }} />
      </Modal>
      <section className={`${styles.wrapper}`}>
        {cartData.map(({ id, amount, quantity, name }) => (
          <Accordion
            id={id}
            key={id}
            expanded={expanded}
            setExpanded={setExpanded}
            headerContext={() =>
              renderHeader({
                quantity,
                name,
                amount,
                expanded,
                setExpanded,
                id,
                setShow,
                setItem,
              })
            }
            bodyContext={() => renderBody({ quantity, name, amount })}
          />
        ))}
      </section>
    </>
  );
};
