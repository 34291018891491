import { EQuerykeys } from "../../models/index";
import { handleDeleted } from "../pouch/algorithms";
import { genericMerchantDB } from "../pouch/DBConn";
import { handleUpdatedOrInserted } from "./../pouch/algorithms";

export const pqClient = ({
  queryClient,
  setIsReplicating,
  name,
  dispatch,
}: any) => {
  const currentData = queryClient.getQueryData(EQuerykeys.product);
  console.log(currentData, "here man3456789");
  if (!currentData || !currentData?.length) {
    genericMerchantDB(name)
      .allDocs({ include_docs: true })
      .then((res: any) => {
        queryClient.setQueryData(
          EQuerykeys.product,
          res.rows.map((row: any) => row.doc)
        );
        console.log(res, "yes my man p");
      })
      .catch((err) => console.log(err, "from generic"));
  } else {
    setIsReplicating(false);
  }
};

export const productqClient = (queryClient: any, res: any) => {
  queryClient.setQueryData(
    [EQuerykeys.product],
    res.rows.map((row: any) => row.doc)
  );
};

export const productChangesqClient = async (queryClient: any, change: any) => {
  try {
    if (change.deleted) {
      queryClient.setQueryData(EQuerykeys.product, (previousData: any) =>
        handleDeleted(previousData, change.id)
      );
    } else {
      queryClient.setQueryData(EQuerykeys.product, (previousData: any) =>
        handleUpdatedOrInserted(previousData, change.doc)
      );
    }
  } catch (err) {
    console.log(err, "from changes query client");
  }
};
