const useContact = (list: Array<any>) => {
  const sortFn = (n: any, k: any) => (n?.firstName > k?.firstName ? 1 : -1);

  const sortedList = list?.sort(sortFn);

  const contactObj: any = {};

  for (let k = 0; k < sortedList?.length; k++) {
    const firstLetter = sortedList[k]?.firstName
      ?.slice(0, 1)
      ?.toLocaleLowerCase();

    if (!contactObj[firstLetter]) {
      contactObj[firstLetter] = [sortedList[k]];
    } else {
      contactObj[firstLetter] = [...contactObj[firstLetter], sortedList[k]];
    }
  }

  return contactObj;
};

export default useContact;
