import React from "react";

import styles from "./index.module.scss";

interface ITextArea {
  label?: string;
  [key: string]: any;
}

const TextArea = ({ label, ...props }: ITextArea) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.label}>{label && <span>{label}</span>}</div>
      <textarea {...props}></textarea>
    </section>
  );
};

export default TextArea;
