
import SearchIconSvgLg from "./../../../svgs/SearchIconSvgLg";

import styles from "./index.module.scss";

const EmptyProductList = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "50vh" }}
    >
      <div className="my-2">
        <SearchIconSvgLg />
      </div>
      <div className={styles.text}>
        <span>Search or Scan Product</span>
      </div>
    </div>
  );
};

export default EmptyProductList;