export const walkIn = [
  { id: 1, date: "Feb. 14, 2021", time: "11:00am", reason: "Emergency Call" },
  { id: 2, date: "Mar. 14, 2021", time: "12:30pm", reason: "Atm Withdrawal" },
  { id: 3, date: "Mar. 14, 2021", time: "12:30pm", reason: "Rest Room" },
  { id: 4, date: "Feb. 14, 2021", time: "11:00am", reason: "Emergency Call" },
  { id: 5, date: "Mar. 14, 2021", time: "12:30pm", reason: "Atm Withdrawal" },
  { id: 6, date: "Mar. 14, 2021", time: "12:30pm", reason: "Rest Room" },
  { id: 7, date: "Feb. 14, 2021", time: "11:00am", reason: "Emergency Call" },
  { id: 8, date: "Mar. 14, 2021", time: "12:30pm", reason: "Atm Withdrawal" },
  { id: 9, date: "Mar. 14, 2021", time: "12:30pm", reason: "Rest Room" },
  { id: 10, date: "Feb. 14, 2021", time: "11:00am", reason: "Emergency Call" },
  { id: 12, date: "Mar. 14, 2021", time: "12:30pm", reason: "Atm Withdrawal" },
  { id: 13, date: "Mar. 14, 2021", time: "12:30pm", reason: "Rest Room" },
];
