import React from "react";

import Products from "./products/index.";
import Records from "./records";

export const SalesTerminal = () => {
  return (
    <section className="container-fluid" style={{ height: "91.2vh" }}>
      <section className="row h-100">
        <Products />
        <Records />
      </section>
    </section>
  );
};
