import React from "react";

import { motion } from "framer-motion";

import { iconVariant } from "../../utils/anims";

const VerifyIconSvg = () => {
  return (
    <svg
      width="40"
      height="48"
      viewBox="0 0 40 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M20 2.90625L37.7188 9.65625V26.5312C37.7188 34.125 30.9688 41.7188 20 45.0938C9.03125 41.7188 2.28125 34.9688 2.28125 26.5312V9.65625L20 2.90625Z"
        stroke="#7F34A6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 0.6, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
      />
      <motion.path
        d="M12.4062 23.1562L17.4688 28.2188L27.5938 16.4062"
        stroke="#7F34A6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 1.3, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
      />
    </svg>
  );
};

export default VerifyIconSvg;
