import React from "react";

import { customerList } from "../../data/customerList";

import useContact from "./../../hooks/useContact";

import styles from "./index.module.scss";

import CloseIconSvg from "./../svgs/CloseIconSvg";

import { motion } from "framer-motion";

import { customerListVariant } from "./../../utils/anims/index";

import Triangle from "../resuable-ui/triangle";
import NameIndexer from "../resuable-ui/name-indexer";

interface ICustomerList {
  setShow: (arg: boolean) => void;
}

const CustomerList = ({ setShow }: ICustomerList) => {
  const listObj = useContact(customerList);

  return (
    <section className="position-relative">
      <motion.section
        variants={customerListVariant}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className={`position-relative ${styles.wrapper}`}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.6, ease: "easeIn" },
          }}
          exit={{ opacity: 0 }}
          className={`${styles.triWrapper} position-absolute w-100 d-flex justify-content-end `}
        >
          <Triangle />
        </motion.div>
        <div
          className={`${styles.icon} position-absolute d-flex justify-content-end`}
          onClick={() => setShow(false)}
        >
          <CloseIconSvg width="17" height="17" viewBox="0 0 25 25" />
        </div>
        <NameIndexer {...{ list: listObj }} />
      </motion.section>
    </section>
  );
};

export default CustomerList;
