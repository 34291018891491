import React from "react";

import NavLayout from "../components/layouts/NavLayout";

import PaymentComponent from "../components/payment";

const Payment = () => {
  return (
    <NavLayout {...{ showBar: false }}>
      <PaymentComponent />
    </NavLayout>
  );
};

export default Payment;
