import React from "react";

import "./App.scss";

import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";

import plugins from "./integrations/pouch/plugins";

import { configEnc } from "./utils/enc";

import Private from "./authentication/PrivateRoute";
import Public from "./authentication/PublicRoute";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  LazyLogin,
  LazyReset,
  LazySalesTerminal,
  LazyPayment,
} from "./pages-chunks";

import NotFound from "./components/UtilComponents/NotFound";
import FallBack from "./components/UtilComponents/FallBack";

plugins();

configEnc();

//once user logs in, the user had access to the db... hence
//1. the system automatically loads all the product which belongs to his/her location...
//2. the system automatically loads all the cart that belongs to a user
//whereby the system filters it by status, "incart: false or incart true"
//this wouuld enable having the parked and the current cart details(with its customer detail and other details attached to it)
//3. Once a product is picked, the  system automatically mark it as in cart and disable it
//t`

function App() {
  // React.useEffect(() => {
  //   document.documentElement.setAttribute("data-theme", "");
  // }, []);

  return (
    <>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <React.Suspense fallback={<FallBack />}>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/reset-code"
              element={
                <Private>
                  <LazyReset />
                </Private>
              }
            />
            <Route
              path="/terminal"
              element={
                <Private>
                  <LazySalesTerminal />
                </Private>
              }
            />
            <Route
              path="/payment"
              element={
                <Private>
                  <LazyPayment />
                </Private>
              }
            />
            <Route
              path="/login"
              element={
                <Public>
                  <LazyLogin />
                </Public>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </React.Suspense>
    </>
  );
}

export default App;
