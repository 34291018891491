export interface IAuthInterface {
  body: string;
}

export interface IRoutes {
  path: string;
  isProtected: Boolean;
  element: React.ExoticComponent;
  children?: any;
}

export interface IinputField {
  id: string;
  onChange: (arg?: any) => void;
  label?: string;
  type: string;
  touched: any;
  value: string | number;
  error: string | undefined;
  handlePassword?: () => void;
  password?: boolean;
  showIcon?: boolean | undefined;
  [key: string]: any;
}

export interface IButton {
  [key: string]: any;
  isBordered?: boolean;
}

export interface ITextBox {
  [key: string]: any;
}

export interface IButtonPad {
  handleClear: (param?: any) => void;
  handleBackSpace: (param?: any) => void;
  handleClick: (param?: any) => void;
}

export interface IPinInput {
  values: any;
  setValues: (param?: any) => void;
  v: boolean;
  setIsEqualPin: (arg: boolean) => void;
}

export interface IVerification {
  text1: string;
  text2?: string;
  text3: string;
}

export interface IconfirmationBtn {
  handleClick: (arg?: any) => void;
  handleBack?: () => void;
  text: string;
  showBackBtn: boolean;
}

export interface IMenuToggle {
  handleToggle: (arg?: any) => void;
  status: boolean;
}

export interface ISideMenuData {
  id: number;
  name: string;
  component: React.FC;
}

interface navState {
  navState: boolean;
  setNavState: (arg: boolean) => void;
  showBar?: boolean;
}

export interface ISideMenu extends navState {}

export interface INavBar extends navState {}

export interface IPopUp {
  [key: string]: any;
  show: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  withBg?: boolean;
}

export interface ICatetogryList {
  id: number;
  name: string;
  quantity: number;
}

export interface IPopUpPosition {
  x: number;
  y: number;
  width?: number | string;
  styleClass?: any;
  children: React.ReactNode;
}

export interface IProductNav {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  toggle: Boolean;
  setToggle: (arg: boolean) => void;
  value: string;
}

export interface ICartH {
  quanity: number;
  product: string;
  amount: number;
  id: number;
}

export interface IModal {
  setShow: (arg: boolean) => void;
  show: boolean;
  children: React.ReactNode;
  columnLayout: string;
}

export interface IPincode {
  _id: string;
  password: string;
}

export interface IFilter {
  filter: string;
  query_params: {
    type: string;
    location: string;
    branch: string;
    [key: string]: string;
  };
}

export enum EDDocId {
  product = "_design/generic",
}

//export enum EDDocTypes {
//  product = "product",
//}

export enum EDDocFilterKeys {
  product = "product",
}

export enum EDDocMapKeys {
  category = "category",
}

export enum EQuerykeys {
  product = "products",
}

export enum ELocalDBNames {
  product = "products",
}

export enum EDDocPath {
  product = `generic/product`,
  category = `generic/category`,
}
