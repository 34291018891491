import React from "react";
import { BackSpaceIcon } from "../../../exports/asset";
import { IButtonPad } from "../../../models";

import styles from "./index.module.scss";
import { btnVariant, btnChildrenVariant } from "./../../../utils/anims/index";

import { motion } from "framer-motion";

const buttonState: string[] = [
  "1",
  "2",
  "3",
  "clear",
  "4",
  "5",
  "6",
  "b",
  "7",
  "8",
  "9",
  "ok",
  "0",
];

const ButtonPads = ({
  handleClick,
  handleClear,
  handleBackSpace,
}: IButtonPad) => {
  return (
    <div
      className={`d-flex justify-content-center col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5 col-xxl-5`}
    >
      <motion.div
        variants={btnVariant}
        initial="hidden"
        animate="visible"
        className={`${styles.wrapper}`}
      >
        {buttonState.map((v, i) => {
          return (
            <React.Fragment key={v.toString()}>
              {v === "clear" ? (
                <motion.button
                  variants={btnChildrenVariant}
                  data-button-class={`item-${v}`}
                  onClick={handleClear}
                >
                  C
                </motion.button>
              ) : v === "b" ? (
                <motion.button
                  variants={btnChildrenVariant}
                  onClick={handleBackSpace}
                  data-button-class={`item-${v}`}
                >
                  <img src={BackSpaceIcon} alt="back space icon" />
                </motion.button>
              ) : (
                <motion.button
                  variants={btnChildrenVariant}
                  data-button-class={`item-${v}`}
                  onClick={() => handleClick(v)}
                >
                  {v}
                </motion.button>
              )}
            </React.Fragment>
          );
        })}
      </motion.div>
    </div>
  );
};

export default ButtonPads;
