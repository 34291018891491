import React from "react";

const RightArrowIconSvg = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.47602 9.73058H17.9632L10.7602 2.5276C10.1845 1.95195 10.1845 1.0073 10.7602 0.431653C10.8967 0.294821 11.0589 0.186263 11.2375 0.112194C11.4161 0.0381252 11.6075 0 11.8008 0C11.9941 0 12.1855 0.0381252 12.3641 0.112194C12.5426 0.186263 12.7048 0.294821 12.8414 0.431653L22.5683 10.1586C22.7052 10.2952 22.8137 10.4574 22.8878 10.6359C22.9619 10.8145 23 11.0059 23 11.1992C23 11.3925 22.9619 11.5839 22.8878 11.7625C22.8137 11.9411 22.7052 12.1033 22.5683 12.2398L12.8414 21.9668C12.7047 22.1034 12.5425 22.2118 12.3639 22.2858C12.1854 22.3597 11.994 22.3978 11.8008 22.3978C11.6075 22.3978 11.4162 22.3597 11.2376 22.2858C11.0591 22.2118 10.8968 22.1034 10.7602 21.9668C10.6235 21.8301 10.5151 21.6679 10.4412 21.4894C10.3672 21.3108 10.3292 21.1194 10.3292 20.9262C10.3292 20.7329 10.3672 20.5416 10.4412 20.363C10.5151 20.1845 10.6235 20.0222 10.7602 19.8856L17.9632 12.6826H1.47602C0.664207 12.6826 0 12.0184 0 11.2066C0 10.3948 0.664207 9.73058 1.47602 9.73058Z"
        fill="white"
      />
    </svg>
  );
};

export default RightArrowIconSvg;
