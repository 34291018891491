import React from "react";

export const AddIconSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 6.25C12.7763 6.25 13.0412 6.35975 13.2366 6.5551C13.4319 6.75045 13.5417 7.0154 13.5417 7.29167V11.4583H17.7083C17.9846 11.4583 18.2496 11.5681 18.4449 11.7634C18.6403 11.9588 18.75 12.2237 18.75 12.5C18.75 12.7763 18.6403 13.0412 18.4449 13.2366C18.2496 13.4319 17.9846 13.5417 17.7083 13.5417H13.5417V17.7083C13.5417 17.9846 13.4319 18.2496 13.2366 18.4449C13.0412 18.6403 12.7763 18.75 12.5 18.75C12.2237 18.75 11.9588 18.6403 11.7634 18.4449C11.5681 18.2496 11.4583 17.9846 11.4583 17.7083V13.5417H7.29167C7.0154 13.5417 6.75045 13.4319 6.5551 13.2366C6.35975 13.0412 6.25 12.7763 6.25 12.5C6.25 12.2237 6.35975 11.9588 6.5551 11.7634C6.75045 11.5681 7.0154 11.4583 7.29167 11.4583H11.4583V7.29167C11.4583 7.0154 11.5681 6.75045 11.7634 6.5551C11.9588 6.35975 12.2237 6.25 12.5 6.25ZM0 12.5C-2.44606e-08 10.8585 0.323322 9.23303 0.951506 7.71646C1.57969 6.19989 2.50043 4.8219 3.66116 3.66117C4.8219 2.50043 6.19989 1.57969 7.71646 0.951506C9.23303 0.323322 10.8585 0 12.5 0C14.1415 0 15.767 0.323322 17.2835 0.951506C18.8001 1.57969 20.1781 2.50043 21.3388 3.66117C22.4996 4.8219 23.4203 6.19989 24.0485 7.71646C24.6767 9.23303 25 10.8585 25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25C9.18479 25 6.00537 23.683 3.66116 21.3388C1.31696 18.9946 4.94004e-08 15.8152 0 12.5ZM12.5 2.08333C9.73733 2.08333 7.08781 3.1808 5.1343 5.1343C3.1808 7.08781 2.08333 9.73733 2.08333 12.5C2.08333 15.2627 3.1808 17.9122 5.1343 19.8657C7.08781 21.8192 9.73733 22.9167 12.5 22.9167C15.2627 22.9167 17.9122 21.8192 19.8657 19.8657C21.8192 17.9122 22.9167 15.2627 22.9167 12.5C22.9167 9.73733 21.8192 7.08781 19.8657 5.1343C17.9122 3.1808 15.2627 2.08333 12.5 2.08333Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};
