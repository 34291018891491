import React from "react";

import styles from "./index.module.scss";

import { formatAmount } from "../../../utils/funcs";

interface ICartSummary {
  name: string;
  amount: number;
  quantity: number;
  styles?: string;
}

const CartSummary = (data: ICartSummary) => {
  return (
    <section
      className={`${styles.wrapper} ${
        data.styles && data.styles
      } d-flex justify-content-between my-2`}
    >
      <div className="d-flex">
        <div>{data.quantity}</div>
        <div className="mx-2">{data.name}</div>
      </div>
      <div>{formatAmount(data.amount)}</div>
    </section>
  );
};

export default CartSummary;
