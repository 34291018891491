import React from "react";

import * as Yup from "yup";

import { Formik, Form, FormikHelpers, FormikProps } from "formik";

import { InputField, PasswordField } from "../../resuable-ui/input";
import Button from "../../resuable-ui/button";
import TextBox from "../../resuable-ui/text-box";

import { loginVariant } from "../../../utils/anims";

import { motion } from "framer-motion";

import styles from "./index.module.scss";
import { useLogin } from "../../../integrations/react_query/mutations";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required."),
  //password: Yup.string()
  //  .length(20, "Password length must be 5 digit")
  //  .required("Password field is required"),
});

type TUserValues = {
  username: string;
  password: string;
};

const initialValues: TUserValues = {
  username: "",
  password: "",
};

const LoginComponent = () => {
  const { mutate, isLoading, error, isError } = useLogin();

  const [showPassword, setShowPassword] = React.useState<Boolean>(false);

  const onSubmit = (
    values: TUserValues,
    actions: FormikHelpers<TUserValues>
  ) => {
    mutate(values);
  };

  return (
    <motion.section variants={loginVariant} animate="visible" initial="hidden">
      <TextBox styleName="text-center mb-5">
        <span>Welcome to ShoppaPOS</span>
      </TextBox>

      <TextBox styleName="text-danger mb-2">
        {(error || isError) && error.message}
      </TextBox>

      <Formik {...{ initialValues, validationSchema, onSubmit }}>
        {(props: FormikProps<TUserValues>) => {
          return (
            <Form>
              <InputField
                onBlur={props.handleBlur}
                id="username"
                label="username"
                onChange={props.handleChange}
                type="text"
                value={props.values.username}
                error={props.errors.username}
                touched={String(props.touched.username)}
                placeholder="Enter your username"
                name="username"
              />

              <div className="mt-3">
                <PasswordField
                  onBlur={props.handleBlur}
                  id="password"
                  label="password *"
                  onChange={props.handleChange}
                  type={showPassword ? "text" : "password"}
                  handleShowPassword={() => setShowPassword(!showPassword)}
                  value={props.values.password}
                  error={props.errors.password}
                  touched={String(props.touched.password)}
                  placeholder="Enter 5 digits passcode"
                  name="password"
                  password={true}
                />
              </div>

              <div className="mt-5 text-center">
                <Button type="submit" disabled={isLoading} styles="w-50 py-3">
                  {isLoading ? "Logging In..." : "Next"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <TextBox styleName="text-center mt-5">
        <div>
          <span className={`${styles.textLight}`}>
            By continuing, I agree to Shoppa’s
          </span>{" "}
          <span className={`${styles.textDark} text-capitalize`}>
            PRIVACY POLICY
          </span>
          <br />
          <span className={`${styles.textLight}`}>and</span>{" "}
          <span className={`${styles.textDark} text-capitalize`}>
            terms of service
          </span>
        </div>
      </TextBox>
    </motion.section>
  );
};

export default LoginComponent;
