import React from "react";
import { IButton } from "../../../models";

import styles from "./index.module.scss";

const Button: React.FC<IButton> = ({
  children,
  styles: customClassName,
  isBordered,
  ...props
}) => {
  return (
    <button
      className={`${customClassName} ${
        isBordered ? styles.buttonBordered : styles.button
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
