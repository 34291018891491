import HomeIconSvg from "../components/svgs/HomeIconSvg";
import ActivityIconSvg from "../components/svgs/ActivityIcon";
import ReportIconSvg from "../components/svgs/ReportIcon";
import SettingIconSvg from "../components/svgs/SettingIcon";
import InventoryIconSvg from "../components/svgs/InventoryIcon";

import { ISideMenuData } from "./../models/index";

export const sideMenuData: ISideMenuData[] = [
  {
    id: 1,
    name: "Home",
    component: HomeIconSvg,
  },

  {
    id: 2,
    name: "Activity",
    component: ActivityIconSvg,
  },

  {
    id: 3,
    name: "Inventory",
    component: InventoryIconSvg,
  },
  {
    id: 4,
    name: "Reports",
    component: ReportIconSvg,
  },
  {
    id: 5,
    name: "Settings",
    component: SettingIconSvg,
  },
];
