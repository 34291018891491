import React from "react";

import { inputVariant } from "../../../utils/anims";

import { motion } from "framer-motion";

import styles from "./index.module.scss";

const InputBox = React.forwardRef(({ styleClass, ...props }: any, ref) => {
  return (
    <motion.input
      variants={inputVariant}
      ref={ref}
      className={`${styles.inputEnter} ${styleClass}`}
      {...props}
      maxLength={1}
    />
  );
});

export default InputBox;
