import React from "react";

const ErrorIconSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.2876 1.60824L0.758434 7.4999C0.68567 7.62591 0.64717 7.76878 0.646762 7.91429C0.646355 8.05979 0.684055 8.20287 0.756111 8.32929C0.828168 8.4557 0.932069 8.56105 1.05748 8.63484C1.18289 8.70863 1.32543 8.7483 1.47093 8.7499H8.52927C8.67477 8.7483 8.81731 8.70863 8.94272 8.63484C9.06813 8.56105 9.17203 8.4557 9.24409 8.32929C9.31615 8.20287 9.35385 8.05979 9.35344 7.91429C9.35303 7.76878 9.31453 7.62591 9.24177 7.4999L5.7126 1.60824C5.63832 1.48578 5.53373 1.38454 5.40893 1.31427C5.28413 1.24401 5.14332 1.20709 5.0001 1.20709C4.85688 1.20709 4.71607 1.24401 4.59127 1.31427C4.46647 1.38454 4.36188 1.48578 4.2876 1.60824V1.60824Z"
        stroke="#C70039"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 3.75V5.41667"
        stroke="#C70039"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 7.08331H5.00531"
        stroke="#C70039"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ErrorIconSvg;
