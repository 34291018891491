import React from "react";

import { iconVariant } from "../../utils/anims";

import { motion } from "framer-motion";

const InventoryIconSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1379 1.00003H7.43816C5.03091 0.990445 3.05766 2.97786 3.00095 5.46992V18.3114C2.94771 20.8442 4.88624 22.9431 7.33168 22.9995C7.36756 22.9995 7.40228 23.0007 7.43816 22.9995H16.6841C19.1075 22.8976 21.0171 20.8239 20.9998 18.3114V7.32429L15.1379 1.00003Z"
        // stroke="#7F5297"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
      />
      <motion.path
        d="M14.9999 1V4.71317C14.9999 6.52571 16.2939 7.99489 17.8945 8H20.9999"
        // stroke="#7F5297"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
      />
      <motion.path
        d="M17.9999 16H6.99988"
        // stroke="#7F5297"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
      />
      <motion.path
        d="M12.9999 11.5H6.99988"
        // stroke="#7F5297"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <motion.path
        d="M9.99988 7.5H6.99988"
        stroke="#7F5297"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
      />
    </svg>
  );
};

export default InventoryIconSvg;
