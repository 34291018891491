import React from "react";

import PaymentModalWrapper from "./../../../../resuable-ui/generic-modal-wrapper";
import TextArea from "./../../../../resuable-ui/text-area";
import { InputField } from "./../../../../resuable-ui/input/index";
import Button from "../../../../resuable-ui/button";

import { Formik, Form, FormikHelpers, FormikProps } from "formik";

import * as Yup from "yup";

import { formatAmount } from "../../../../../utils/funcs";

import styles from "./index.module.scss";

const validationSchema = Yup.object().shape({
  amount: Yup.string().required("please enter amount paid"),
});

type TPayment = {
  amount: "";
};

const initialValues: TPayment = {
  amount: "",
};

interface ICashModalContent {
  title: string;
  setShow: (arg: boolean) => void;
}

const CashModalContent = ({ title, setShow }: ICashModalContent) => {
  const onSubmit = (values: TPayment, actions: FormikHelpers<TPayment>) => {
    console.log("submission of form", values, actions, "from formik man");
  };
  return (
    <PaymentModalWrapper {...{ title }}>
      <Formik {...{ initialValues, validationSchema, onSubmit }}>
        {(props: FormikProps<TPayment>) => {
          return (
            <Form className="mt-4">
              <span className={styles.balance}>Balance due - 4,500.00</span>
              <div className="d-flex align-items-start justify-content-between">
                <div className="flex-fill">
                  <InputField
                    onBlur={props.handleBlur}
                    id="amount"
                    onChange={props.handleChange}
                    type="text"
                    value={props.values.amount}
                    error={props.errors.amount}
                    touched={props.touched.amount}
                    placeholder="Enter amount paid"
                    name="amount"
                    showIcon={true}
                  />
                </div>

                <div className={`${styles.btnMargin} w-50`}>
                  <Button type="button" styles={`${styles.btnPadding} w-100`}>
                    &#8358; &nbsp;{formatAmount(4000)}
                  </Button>
                </div>
              </div>

              <div className="my-3">
                <TextArea
                  className={styles.textArea}
                  label="Note (optional)"
                  placeholder="Type in any note here you want attached to the sale..."
                />
              </div>

              <div className="w-100 text-center mt-5 mb-3">
                <Button
                  type="button"
                  disabled={false}
                  isBordered={true}
                  styles="py-4 mx-3"
                  style={{ width: "45%" }}
                  onClick={() => setShow(false)}
                >
                  Clear
                </Button>
                <Button
                  type="submit"
                  disabled={false}
                  style={{ width: "45%" }}
                  styles="py-4"
                >
                  Ok
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </PaymentModalWrapper>
  );
};

export default CashModalContent;
