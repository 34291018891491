import React from "react";

import styles from "./index.module.scss";

import SalesNav from "./sales-nav";
import AddCustomer from "./add-customer";
import { CartItems } from "./cart-items";

import CartBreakDown from "../../resuable-ui/cart-breakdown";
import ActionButtons from "./action-btns";

import EmptyInCart from "./empty-incart";

const Records = () => {
  const isTrue = false;
  return (
    <section
      className={`${styles.wrapper} col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 p-3 px-lg-4 px-xl-4 px-xxl-4`}
    >
      <SalesNav />

      <AddCustomer />
      {isTrue ? (
        <EmptyInCart />
      ) : (
        <>
          <CartItems />
          <CartBreakDown
            {...{ vat: 15, subTotal: 4656, items: 4, total: 4500 }}
          />
        </>
      )}

      <ActionButtons />
    </section>
  );
};

export default Records;
