import { triggerToast } from "../../toast";

import { removeSession } from "../../utils/storage";
import { getEnvs } from "./../../utils/getEnvs";

export const checkLocalInfo = (
  condition: any,
  message: string,
  navigate: (arg: string, arg2: any) => void
) => {
  const { user } = getEnvs();
  if (!condition) {
    navigate("/login", { replace: true });

    removeSession(user);

    triggerToast({
      type: "error",
      message: "Something went wrong. Please reauthenticate",
      duration: 3000,
    });

    throw new Error(message);
  }
};
