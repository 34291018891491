import React from "react";
import KeyPadIconSvg from "../../svgs/KeyPadIconSvg";

import styles from "./index.module.scss";

import { formatAmount } from "../../../utils/funcs";

interface IPayAmountBar {
  amount: number;
}

const PayAmountBar = ({ amount }: IPayAmountBar) => {
  return (
    <section className={`${styles.wrapper} d-flex align-items-center`}>
      <div className={`${styles.amount} flex-fill text-center`}>
        {formatAmount(amount)}
      </div>
      <div className={styles.icon}>
        <KeyPadIconSvg />
      </div>
    </section>
  );
};

export default PayAmountBar;
