import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback  from "./ErrorFallback";

const ErrorBoundaryProvider: React.FC = ({ children }) => {
  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    console.log(error, "error from error boundary", info, "info from error boundary")
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryProvider;
