import React from "react";
import LoginComponent from "./../components/auth/Login/index";

const Login = () => {
  return (
    <section className="container-fluid">
      <div
        className="row justify-content-center align-items-center overflow-hidden"
        style={{ height: "100vh" }}
      >
        <div className="col-12 col-sm-8 col-md-7 col-lg-6 col-xl-4 col-xxl-4">
          <LoginComponent />
        </div>
      </div>
    </section>
  );
};

export default Login;
