import React from "react";

import { formatAmount } from "../../../utils/funcs";

import styles from "./index.module.scss";

interface ICartBreakDown {
  subTotal: number;
  vat: number;
  items: number;
  total: number;
}

const CartBreakDown = (data: ICartBreakDown) => {
  return (
    <section className={`${styles.wrapper}`}>
      <div
        className={`${styles.items} d-flex align-items-center justify-content-between`}
      >
        <div>Sub total</div>
        <div>{formatAmount(data.subTotal)}</div>
      </div>
      <div
        className={`${styles.items} d-flex align-items-center justify-content-between`}
      >
        <div>VAT(5%)</div>
        <div>{formatAmount(data.vat)}</div>
      </div>
      <div
        className={`${styles.items} d-flex align-items-center justify-content-between`}
      >
        <div>
          Total <span>({data.items} items)</span>
        </div>
        <div>{formatAmount(data.total)}</div>
      </div>
    </section>
  );
};
export default CartBreakDown;
