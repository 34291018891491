import React from "react";

import styles from "./index.module.scss";

import { PlaceholderIconSvg } from "./../../svgs/PlaceholderIconSvg";

interface INameIndex {
  list: any;
  styleClass?: any;
}

export const NameIndexer = ({ list, styleClass }: INameIndex) => {
  return (
    <section
      className={`position-relative  ${styles.innerWrapper} ${
        styleClass && styleClass
      }`}
    >
      {Object.entries(list).map(([nameIndex, valuesIndex]: any) => {
        return (
          <React.Fragment key={nameIndex}>
            <div className={`${styles.nameIndex} text-uppercase`}>
              {nameIndex}
            </div>
            <div>
              {valuesIndex?.map((value: any, i: number) => {
                return (
                  <div
                    key={i}
                    className={`${styles.valueIndex} d-flex align-items-center justify-content-between`}
                  >
                    <div className="d-flex align-items-center">
                      <PlaceholderIconSvg
                        width="35"
                        height="35"
                        viewBox="0 0 50 50"
                      />
                      <div className="mx-2">{value?.firstName}</div>
                    </div>
                    <div>{value?.phoneNumber}</div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </section>
  );
};

export default NameIndexer;
