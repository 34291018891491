import React from "react";

import PaymentModalWrapper from "./../../../../resuable-ui/generic-modal-wrapper";
import { InputField } from "./../../../../resuable-ui/input/index";
import Button from "../../../../resuable-ui/button";

import { Formik, Form, FormikHelpers, FormikProps } from "formik";

import * as Yup from "yup";

import styles from "./index.module.scss";
import AccountDetails from "../../../../resuable-ui/account-details";

const validationSchema = Yup.object().shape({
  customerName: Yup.string().required("please enter customer name"),
  phone: Yup.string().required("please enter phone number"),
});

type TPayment = {
  customerName: string;
  phone: string;
};

const initialValues: TPayment = {
  customerName: "",
  phone: "",
};

interface ICashModalContent {
  title: string;
  setShow: (arg: boolean) => void;
}

const banks = [
  { id: 1, name: "GTB", onClick: () => "" },
  { id: 2, name: "FCMB", onClick: () => "" },
  { id: 3, name: "Zenith", onClick: () => "" },
  { id: 4, name: "FBN", onClick: () => "" },
];

const BankModalContent = ({ title, setShow }: ICashModalContent) => {
  const onSubmit = (values: TPayment, actions: FormikHelpers<TPayment>) => {
    console.log("submission of form", values, actions, "from formik man");
  };
  return (
    <PaymentModalWrapper {...{ title }}>
      <div className={`d-flex ${styles.bank}`}>
        {banks.map(({ name, ...props }) => (
          <Button {...props} isBordered={true} styles="py-3 mx-1 w-25">
            {name}
          </Button>
        ))}
      </div>

      <div className="mt-4">
        <AccountDetails
          {...{ name: "Goodness supermarket & stores", number: "0247835834" }}
        />
      </div>

      <Formik {...{ initialValues, validationSchema, onSubmit }}>
        {(props: FormikProps<TPayment>) => {
          return (
            <Form className="mt-3">
              <div className="d-flex align-items-start justify-content-between">
                <div className="flex-fill ">
                  <InputField
                    onBlur={props.handleBlur}
                    id="customerName"
                    onChange={props.handleChange}
                    type="text"
                    value={props.values.customerName}
                    error={props.errors.customerName}
                    touched={props.touched.customerName}
                    placeholder="Enter customer name"
                    name="customerName"
                    showIcon={true}
                    label="Customer name*"
                  />
                </div>
                <div className="flex-fill" style={{ marginLeft: "1em" }}>
                  <InputField
                    onBlur={props.handleBlur}
                    id="phone"
                    onChange={props.handleChange}
                    type="text"
                    value={props.values.phone}
                    error={props.errors.phone}
                    touched={props.touched.phone}
                    placeholder="Enter amount paid"
                    name="phone"
                    showIcon={true}
                    label="Phone*"
                  />
                </div>
              </div>

              <div className="w-100 text-center mt-5 mb-3">
                <Button
                  type="button"
                  disabled={false}
                  isBordered={true}
                  styles="py-4 mx-3"
                  style={{ width: "45%" }}
                  onClick={() => setShow(false)}
                >
                  Clear
                </Button>
                <Button
                  type="submit"
                  disabled={false}
                  style={{ width: "45%" }}
                  styles="py-4"
                >
                  Ok
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </PaymentModalWrapper>
  );
};

export default BankModalContent;
