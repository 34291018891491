import { Variants } from "framer-motion";

export const loginVariant: Variants = {
  hidden: { opacity: 1, scale: 0.5 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

export const inputVariant: Variants = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export const btnVariant: Variants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.09,
      staggerChildren: 0.09,
    },
  },
};

export const btnChildrenVariant: Variants = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { bounce: 0.25, type: "spring" },
  },
};

export const iconVariant = {
  hidden: {
    pathLength: 0,
    stroke: "#7f34a6",
  },
  visible: {
    pathLength: 1,
    stroke: "#7F34A6",
  },
};

export const logoVariant = {
  hidden: {
    pathLength: 0,
    stroke: "#ffffff",
  },
  visible: {
    pathLength: 1,
    stroke: "#ffffff",
  },
};

export const iconVariantInfinite: Variants = {
  hidden: {
    pathLength: 0,
    stroke: "#ffffff",
  },
  visible: {
    pathLength: 1,
    stroke: "#ffffff",
    transition: { repeat: Infinity, duration: 2, ease: "easeIn" },
  },
};

export const sideMenuVariant: Variants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring", duration: 0.2 },
  },
  exit: {
    x: -100,
    opacity: 1,
    transition: { type: "spring", duration: 0.8 },
  },
};

export const sMenuChildrenVariant: Variants = {
  hidden: { y: 5 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { bounce: 0.5, type: "spring", velocity: 100 },
  },
};

export const translateInfinite: Variants = {
  hidden: { x: -10 },
  visible: {
    x: 0,
    transition: { repeat: Infinity, duration: 3, delay: 3 },
  },
};

export const categoryListVariant: Variants = {
  hidden: {
    x: -300,
    scale: 0,
    transition: {
      ease: "easeIn",
      duration: 0.4,
      staggerChildren: 0.4,
    },
  },
  visible: {
    x: 0,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: "easeIn",
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  exit: {
    x: -400,
    scale: 0,
    transition: {
      duration: 0.4,
      ease: "easeOut",
      when: "afterChildren",
      staggerChildren: 0.4,
    },
  },
};

export const categoryListInnerVariant: Variants = {
  hidden: {
    scale: 0,
    x: -300,
    transition: {
      duration: 0.4,
      staggerChildren: 0.4,
      ease: "easeIn",
    },
  },
  visible: {
    scale: 1,
    x: 0,
    transition: {
      ease: "easeIn",
      duration: 0.2,
      when: "beforeChildren",
      staggerChildren: 0.2,
      staggerDirection: 1,
    },
  },
  exit: {
    scaleX: 0.3,
    scaleY: 0.8,
    y: -1000,
    transition: {
      ease: "easeOut",
      duration: 0.1,
      when: "afterChildren",
      staggerChildren: 0.2,
    },
  },
};

export const categoryListChildrenVariant: Variants = {
  hidden: { x: 300, opacity: 0.2 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { bounce: 0.5, type: "spring", velocity: 1000, duration: 0.1 },
  },
  // exit: { x: -300, opacity: 0.2, transition: { duration: 0.1, bounce: 0.5 } },
};

export const accordionVariant = {
  open: {
    opacity: 1,
    height: "auto",
    transition: { ease: "easeInOut", duration: 0.2 },
  },
  collapsed: {
    opacity: 0,
    height: 0,
    transition: { ease: "easeInOut", duration: 0.2 },
  },
};

export const customerListVariant = {
  hidden: {
    x: 300,
    y: -270,
    scale: 0,
    skewX: "30deg",
    skewY: "30deg",
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
  visible: {
    x: 0,
    y: 0,
    skewX: "0deg",
    skewY: "0deg",
    scale: 1,
    transition: {
      ease: "easeIn",
      duration: 0.4,
    },
  },
};

export const selectVariant = {
  hidden: {
    scale: 0,
    skewX: "30deg",
    skewY: "60deg",
    rotate: "-40deg",
    opacity: 0.5,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    skewX: "0deg",
    skewY: "0deg",
    rotate: "0deg",
    transition: {
      ease: "easeIn",
      duration: 0.4,
    },
  },
};

export const salesModalVariant = {
  hidden: {
    scale: 0,
    y: 500,
    skewX: "20deg",
    skewY: "-20deg",
    opacity: 0.5,
    transition: {
      ease: "easeIn",
      duration: 0.45,
    },
  },
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    x: 0,
    skewX: "0deg",
    skewY: "0deg",
    transition: {
      ease: "easeIn",
      duration: 0.35,
    },
  },
};

export const addCustomerVariant = {
  hidden: {
    opacity: 0,
    scale: 1,
    transition: {
      ease: "easeOut",
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: "easeIn",
      duration: 0.2,
    },
  },
  exit: {
    scale: 1.1,
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.3,
    },
  },
};
