import React from "react";

import { Formik, Form, FormikHelpers, FormikProps } from "formik";

import styles from "./index.module.scss";

import { InputField } from "./../../../../resuable-ui/input/index";
import Button from "../../../../resuable-ui/button";

import RecordModalWrapper from "../../../../resuable-ui/generic-modal-wrapper";

import TextArea from "./../../../../resuable-ui/text-area/index";

type TParkValues = {
  note: string;
};

const initialValues: TParkValues = {
  note: "",
};

export const ParkModalContent = () => {
  const onSubmit = (
    values: TParkValues,
    actions: FormikHelpers<TParkValues>
  ) => {
    console.log("submission of form", values, actions, "from formik man");
  };

  return (
    <RecordModalWrapper title="Park Sales">
      <Formik {...{ initialValues, onSubmit }}>
        {(props: FormikProps<TParkValues>) => {
          return (
            <Form>
              <div className={styles.wrapper}>
                <div className="">
                  <InputField
                    onBlur={props.handleBlur}
                    id=""
                    onChange={props.handleChange}
                    type="text"
                    value={"Walk in customer man"}
                    error={""}
                    touched={""}
                    placeholder=""
                    name=""
                    showIcon={true}
                  />
                </div>
                <div className="my-4">
                  <TextArea className={styles.textArea} label="Note" />
                </div>

                <div className="w-100 text-center my-3">
                  <Button
                    type="button"
                    disabled={false}
                    style={{ width: "40%" }}
                    styles="py-4"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </RecordModalWrapper>
  );
};
