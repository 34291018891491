import React from "react";

//import { ProductListWrapper } from "./../../product-list-wrapper/index";
import EmptyProductList from "../../empty-list";
import ProductListItem from "./list-item/index";

const ProductListView = ({
  data,
  isLoading,
  isError,
  error,
  handleAddToCart,
}: {
  [key: string]: any;
}) => {
  return (
    <div className="row align-items-center">
      {isLoading ? (
        "pulling up"
      ) : error || isError ? (
        "Something went wrong."
      ) : !data?.length ? (
        <EmptyProductList />
      ) : (
        (data || []).map((product: any) => (
          <ProductListItem
            {...{ product, handleAddToCart }}
            key={product?._id}
          />
        ))
      )}
    </div>
  );
};

export default ProductListView;
