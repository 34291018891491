import { useMutation } from "react-query";

import { shoppaLogin } from "../pouch/services/authService";

import { triggerToast } from "../../toast/index";

import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../store/storeConfig";
import { IPincode } from "../../models";
import { shoppaPinCode } from "../pouch/services/pincodeService";
import { addToCart } from "./queryFn";

interface IuseLogin {
  username: string;
  password: string;
}

export const useLogin = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  return useMutation(
    ({ username, password }: IuseLogin) =>
      shoppaLogin(username, password, navigate, dispatch),
    {
      onSuccess: async () => {
        triggerToast({
          type: "success",
          message: "Login Successful",
          duration: 3000,
        });
      },
      onError: async (err: any) => {
        console.log(err.message, "this is an error manx");
        triggerToast({
          type: "error",
          message: err.message,
          duration: 5000,
        });

        return err.message;
      },
    }
  );
};

export const usePinCode = () => {
  const navigate = useNavigate();

  return useMutation((arg: IPincode) => shoppaPinCode(arg, navigate), {
    onSuccess: async (data: any) => {
      console.log("here is the sucess data man", data);
    },
    onError: async (err: any) => {
      console.log(err.message, "this is an error manx");
      triggerToast({
        type: "error",
        message: err.message,
        duration: 5000,
      });
      return err.message;
    },
  });
};

export const useAddToCart = () => {
  return useMutation((data: any) => addToCart(data), {
    onMutate: async () => {
      console.log("mutating");
    },
    onSuccess: () => {
      console.log("success man");
      triggerToast({
        type: "success",
        message: "Adding to cart successful!",
        duration: 2000,
      });
    },
    onError: async () => {
      triggerToast({
        type: "error",
        message: "Adding to cart failed!.",
        duration: 3000,
      });
      console.log("an  error occcured man");
    },
  });
};
