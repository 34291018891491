import React from "react";

import { motion } from "framer-motion";
import { iconVariantInfinite } from "../../utils/anims";

const SearchIconSvgSm = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={iconVariantInfinite}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 3, ease: [1, 0.2, 0.8, 1] },
        }}
        d="M9.99281 19.9856C4.45833 19.9856 0 15.5273 0 9.99281C0 4.45833 4.45833 0 9.99281 0C15.5273 0 19.9856 4.45833 19.9856 9.99281C19.9856 15.5273 15.5273 19.9856 9.99281 19.9856ZM9.99281 1.53736C5.30388 1.53736 1.53736 5.30388 1.53736 9.99281C1.53736 14.6818 5.30388 18.4483 9.99281 18.4483C14.6818 18.4483 18.4483 14.6818 18.4483 9.99281C18.4483 5.30388 14.6818 1.53736 9.99281 1.53736Z"
        fill="#D3A8E9"
      />
      <motion.path
        variants={iconVariantInfinite}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 0.9, ease: "easeInOut" },
          fill: { duration: 1, ease: [0.6, 0.2, 0.8, 1] },
        }}
        d="M17.4351 16.3477L24.3378 23.2504L23.2509 24.3373L16.3481 17.4346L17.4351 16.3477Z"
        fill="#D3A8E9"
      />
    </svg>
  );
};

export default SearchIconSvgSm;
