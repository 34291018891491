import React from "react";

import { motion } from "framer-motion";

import { iconVariantInfinite } from "./../../utils/anims/index";

const SearchIconSvgLg = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={iconVariantInfinite}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 5, ease: "easeInOut" },
          fill: { duration: 5, ease: [1, 0.2, 0.8, 1] },
        }}
        d="M34.2632 64.6793C16.9967 65.4242 2.48743 52.1151 1.74249 34.8486C0.997551 17.582 14.3066 3.07276 31.5732 2.32782C48.8397 1.58288 63.349 14.892 64.0939 32.1585C64.8389 49.4251 51.5298 63.9343 34.2632 64.6793ZM31.7801 7.12409C17.1515 7.75522 5.90763 20.013 6.53875 34.6416C7.16988 49.2703 19.4277 60.5141 34.0563 59.883C48.6849 59.2519 59.9288 46.9941 59.2977 32.3654C58.6665 17.7368 46.4087 6.49296 31.7801 7.12409Z"
        fill="#C4C4C4"
      />
      <motion.path
        variants={iconVariantInfinite}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 5, ease: "easeInOut" },
          fill: { duration: 5, ease: [1, 1, 0.8, 1] },
        }}
        
        d="M56.992 52.3273L79.4564 72.9335L76.2117 76.4707L53.7474 55.8646L56.992 52.3273Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export default SearchIconSvgLg;
