import React from "react";

import { AddIconSvg } from "../../../svgs/AddIconSvg";
import PlaceholderIconSvg from "../../../svgs/PlaceholderIconSvg";

import styles from "./index.module.scss";

import PopUpPosition from "../../../pop-up/pop-up-position";
import PopUp from "../../../pop-up";
import CustomerList from "../../../customer-list";

import usePopup from "../../../../hooks/usePopup";
import useWindowWidth from "./../../../../hooks/useWindowWidth";

import Modal from "../../../resuable-ui/modal";

import CustomerForm from "../customer-form";
import SingleCustomerDetail from "../../../resuable-ui/single-customer-detail";

const AddCustomer = () => {
  const ref = React.useRef<HTMLDivElement>(null);

  const { width } = useWindowWidth();

  const { x, y, show, setShow } = usePopup({
    ref,
    marginX: width <= 1000 ? 390 : 475,
    marginY: 90,
  });

  const [showCustomerForm, setCustomerForm] = React.useState(false);

  const isCustomer = false;

  const [customerDetail] = React.useState({
    name: "Bernard Kelechi",
    email: "Bernardkeecee@ymail.com",
    phone: "+234 (0) 703 345 3748",
  });

  return (
    <>
      <Modal
        {...{
          show: showCustomerForm,
          setShow: setCustomerForm,
          columnLayout: "col-11 col-lg-10 col-xl-8 col-xxl-8",
        }}
      >
        <CustomerForm />
      </Modal>

      <PopUp {...{ show, setShow }}>
        <PopUpPosition
          {...{
            x,
            y,
            width: width <= 1000 ? "360px" : "450px",
            styleClass: `${styles.shadow} ${styles.rounded}`,
          }}
        >
          <CustomerList {...{ setShow }} />
        </PopUpPosition>
      </PopUp>
      <section
        ref={ref}
        className={`d-flex align-items-center justify-content-between ${styles.wrapper}`}
      >
        <div>
          <PlaceholderIconSvg width="40" height="40" viewBox="0 0 50 50" />
        </div>
        {isCustomer ? (
          <SingleCustomerDetail {...{ data: customerDetail }} />
        ) : (
          <>
            <input placeholder="Add customer" onClick={() => setShow(!show)} />
            <button onClick={() => setCustomerForm(true)}>
              <AddIconSvg />
            </button>
          </>
        )}
      </section>
    </>
  );
};

export default AddCustomer;
