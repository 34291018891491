import React from "react";

import { Formik, Form, FormikHelpers, FormikProps } from "formik";

import styles from "./index.module.scss";

import Button from "../../../../resuable-ui/button";

import RecordModalWrapper from "../../../../resuable-ui/generic-modal-wrapper";

import TextArea from "./../../../../resuable-ui/text-area/index";

type TParkValues = {
  note: string;
};

const initialValues: TParkValues = {
  note: "",
};

interface IDiscardContent {
  setShow: (arg: boolean) => void;
}

export const DiscardModalContent = ({ setShow }: IDiscardContent) => {
  const onSubmit = (
    values: TParkValues,
    actions: FormikHelpers<TParkValues>
  ) => {
    console.log("");
  };

  const renderTitle = () => <span className={styles.title}>Discard Sale?</span>;

  return (
    <RecordModalWrapper title={renderTitle}>
      <Formik {...{ initialValues, onSubmit }}>
        {(props: FormikProps<TParkValues>) => {
          return (
            <Form>
              <div className={styles.wrapper}>
                <div className="my-4">
                  <TextArea className={styles.textArea} label="Note" />
                </div>

                <div className="w-100 text-center my-3">
                  <Button
                    type="button"
                    disabled={false}
                    isBordered={true}
                    styles="py-4 mx-3"
                    style={{ width: "35%" }}
                    onClick={() => setShow(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    disabled={false}
                    style={{ width: "35%" }}
                    styles="py-4"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </RecordModalWrapper>
  );
};
