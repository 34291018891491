import React from "react";

import RecordModalWrapper from "../../../../resuable-ui/generic-modal-wrapper";

import WalkInList from "../../../../resuable-ui/walk-in-list";

import { walkIn } from "./../../../../../data/walk-in";

export const RetrieveModalContent = () => {
  return (
    <RecordModalWrapper title="Retrieve Sales">
      <WalkInList {...{ data: walkIn }} />
    </RecordModalWrapper>
  );
};
