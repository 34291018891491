import React from "react";
import Button from "../../../resuable-ui/button";

import styles from "./index.module.scss";

import Modal from "../../../resuable-ui/modal";
import CashModalContent from "../payment-modals/cash";
import BankModalContent from "../payment-modals/bank";

const buttonData = [
  { id: 1, name: "Cash", onClick: () => console.log("clicking man") },
  {
    id: 2,
    name: "Debit/Credit Card",
    onClick: () => console.log("clicking man"),
  },
  {
    id: 3,
    name: "Bank Transfer",
    onClick: () => console.log("clicking man"),
    disabled: true,
  },
  {
    id: 4,
    name: "Layby",
    onClick: () => console.log("clicking man"),
    disabled: true,
  },
  {
    id: 5,
    name: "Cash",
    onClick: () => console.log("clicking man"),
    disabled: true,
  },
  {
    id: 6,
    name: "Store Card",
    onClick: () => console.log("clicking man"),
    disabled: true,
  },
  {
    id: 7,
    name: "GiftCard",
    onClick: () => console.log("clicking man"),
    disabled: true,
  },
  {
    id: 8,
    name: "GiftCard",
    onClick: () => console.log("clicking man"),
    disabled: true,
  },
];

const PaymentButtons = () => {
  const [show, setShow] = React.useState(false);

  const [type, setType] = React.useState<{
    name: string;
    id: number | null;
  }>({ id: null, name: "" });

  const handleModal = (name: string, id: number) => {
    setType({ id, name });
    setShow(true);
  };

  return (
    <>
      <Modal
        {...{
          show,
          setShow,
          columnLayout: "col-9 col-lg-8 col-xl-7 col-xxl-6",
        }}
      >
        {type?.id === 1 || type?.id === 2 ? (
          <CashModalContent
            {...{ title: `${type.name} Payment Confirmation`, setShow }}
          />
        ) : (
          <BankModalContent
            {...{ title: `${type.name} Payment Confirmation`, setShow }}
          />
        )}
      </Modal>
      <section className={`${styles.wrapper}`}>
        <div className="container text-center">
          <div className="row">
            {buttonData.map(({ id, name, ...props }) => {
              return (
                <div className="col-6 my-3">
                  <Button
                    {...props}
                    key={id}
                    type="button"
                    styles="w-75 py-3 py-lg-4 py-xl-4 py-xxl-4"
                    onClick={() => handleModal(name, id)}
                  >
                    {name}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentButtons;
