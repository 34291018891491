import React from 'react'

const GridIconSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3.5" cy="3.5" r="3.5" fill="#7F5297" />
      <circle cx="3.5" cy="12.5" r="3.5" fill="#7F5297" />
      <circle cx="12.5" cy="3.5" r="3.5" fill="#7F5297" />
      <circle cx="12.5" cy="12.5" r="3.5" fill="#7F5297" />
    </svg>
  );
}


export default GridIconSvg; 