import React from "react";

import { motion } from "framer-motion";

import { selectVariant } from "../../../utils/anims";

import styles from "./index.module.scss";

interface ICustomDropDown {
  setShow: (arg: boolean) => void;
  data: any;
  [props: string]: any;
  title: string;
  setSelected: any;
  currentValue: string;
  type: string;
  clearField?: () => void;
}

export const CustomSelectDropDown = React.memo(
  ({
    setShow,
    data,
    title,
    setSelected,
    currentValue,
    type,
    clearField,
  }: ICustomDropDown) => {
    const handleSelected = (arg: string) => {
      clearField && clearField();
      setSelected(type, arg);
      setShow(false);
    };

    return (
      <motion.section
        variants={selectVariant}
        initial="hidden"
        animate="visible"
        exit="hidden"
        onClick={() => setShow(false)}
        className={`${styles.wrapper} position-relative`}
      >
        {title && (
          <div
            className={`position-absolute w-100 ${styles.title} text-center py-1`}
          >
            <span>{title}</span>
          </div>
        )}
        <section className={`position-relative ${styles.innerWrapper}`}>
          <div className={`${styles.body}`}>
            {data?.map((d: string) => {
              return (
                <div
                  className={`d-flex align-items-center ${styles.item} ${
                    currentValue === d && styles.colored
                  }`}
                  onClick={() => handleSelected(d)}
                >
                  <div
                    className={`${
                      currentValue === d ? styles.dot : styles.circle
                    } d-flex align-items-center justify-content-center`}
                  >
                    <div className="text-center"></div>
                  </div>
                  {d}
                </div>
              );
            })}
          </div>
        </section>
      </motion.section>
    );
  }
);
