import React from "react";

import { useNavigate } from "react-router-dom";

import { shoppaReplicate } from "./../integrations/pouch/services/replicateService";

import { useAuthenticate } from "./useAuthenticate";

import { IFilter } from "./../models/index";

import { useQueryClient } from "react-query";
interface IReplicator {
  localDBName: string;
  filter: IFilter;
  filterFunc: (arg: any) => any;
}

export const useReplicator = ({
  localDBName,
  filter,
  filterFunc,
}: IReplicator) => {
  const [isReplicating, setIsReplicating] = React.useState(false);

  const navigate = useNavigate();

  const replicateRef = React.useRef<any>(null);

  const isAuthenticated = useAuthenticate();

  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (isAuthenticated) {
      replicateRef.current = shoppaReplicate({
        localDBName,
        filterFunc,
        onComplete: (info) => {
          setIsReplicating(true);

          console.log("the replication was successful man", info);
        },
        onError: (info) => {
          setIsReplicating(true);
          console.log("the replication was error man", info);
        },
        onChange: () => {
          setIsReplicating(true);
        },
        filter,
      });

      return () => replicateRef.current && replicateRef.current.cancel();
    } else {
      navigate("/login", { replace: true });
    }

    //eslint-disable-next-line
  }, [isAuthenticated, localDBName, filter, queryClient]);

  return { isReplicating };
};
