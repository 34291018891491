const states = [
  "Abia",
  "Borno",
  "Federal Capital Territory",
  "Akwa Ibom",
  "Ebonyi",
  "Ogun",
  "Cross River",
  "Imo",
  "Rivers",
  "Kogi",
  "Benue",
  "Ekiti",
  "Oyo",
  "Niger",
  "Lagos",
  "Anambra",
  "Osun",
  "Kano",
  "Gombe",
  "Edo",
  "Ondo",
  "Nasarawa",
  "Kebbi",
  "Bauchi",
  "Enugu",
  "Delta",
  "Zamfara",
  "Taraba",
  "Kwara",
  "Jigawa",
  "Yobe",
  "Katsina",
  "Plateau",
  "Sokoto",
  "Kaduna",
  "Bayelsa",
  "Adamawa",
];

const sortFn = (n: any, k: any) => (n > k ? 1 : -1);

//let copiedStates = [new Set(states)];

const uniqueStates = states.sort(sortFn);

export default uniqueStates;
