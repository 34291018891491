import React from "react";

const EditIconSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 8.99952C13.8353 8.99952 13.5 9.33552 13.5 9.74952V15.7495C13.5 16.1628 13.164 16.4995 12.75 16.4995H2.25C1.836 16.4995 1.5 16.1628 1.5 15.7495V5.24952C1.5 4.83626 1.836 4.49951 2.25 4.49951H8.25001C8.66477 4.49951 9.00002 4.16351 9.00002 3.74951C9.00002 3.33551 8.66477 2.99951 8.25001 2.99951H2.25C1.0095 2.99951 0 4.00901 0 5.24952V15.7495C0 16.99 1.0095 17.9995 2.25 17.9995H12.75C13.9905 17.9995 15 16.99 15 15.7495V9.74952C15 9.33477 14.6648 8.99952 14.25 8.99952Z"
        fill="#7F5297"
      />
      <path
        d="M7.03216 8.31633C6.97966 8.36883 6.94441 8.43558 6.92941 8.50758L6.39916 11.1596C6.37441 11.2826 6.41341 11.4093 6.50191 11.4986C6.57316 11.5698 6.66916 11.6081 6.76741 11.6081C6.79141 11.6081 6.81616 11.6058 6.84091 11.6006L9.49217 11.0703C9.56567 11.0553 9.63242 11.0201 9.68417 10.9676L15.6182 5.03358L12.9669 2.38232L7.03216 8.31633Z"
        fill="#7F5297"
      />
      <path
        d="M17.4507 0.548439C16.7195 -0.182813 15.53 -0.182813 14.7995 0.548439L13.7615 1.58644L16.4127 4.2377L17.4507 3.19969C17.8047 2.84644 17.9997 2.37544 17.9997 1.87444C17.9997 1.37344 17.8047 0.902439 17.4507 0.548439Z"
        fill="#7F5297"
      />
    </svg>
  );
};

export default EditIconSvg;
