import React from "react";

import styles from "./index.module.scss";

//{
//  0 === 0 && styles.faded;
//}
export const GridToggle = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <button
      onClick={onClick}
      disabled={false}
      className={`${styles.wrapper} $ mx-4`}
    >
      {children}
    </button>
  );
};
