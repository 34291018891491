import React from "react";

//import { iconVariant } from "../../utils/anims";

import { motion } from "framer-motion";

const CloseIconSvg = (props: any) => {
  return (
    <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.path
        d="M25 2.1875L22.8125 0L12.5 10.3125L2.1875 0L0 2.1875L10.3125 12.5L0 22.8125L2.1875 25L12.5 14.6875L22.8125 25L25 22.8125L14.6875 12.5L25 2.1875Z"
        fill="#353535"

        // variants={iconVariant}
        // initial="hidden"
        // animate="visible"
        // transition={{
        //   default: { duration: 2.2, ease: "easeInOut" },
        //   fill: { duration: 1.2, ease: [1, 0, 0.8, 1] },
        // }}
      />
    </svg>
  );
};

export default CloseIconSvg;
