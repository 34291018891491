import React from "react";

import { motion } from "framer-motion";

import { salesModalVariant } from "../../../utils/anims/index";

import styles from "./index.module.scss";

interface IRecordModalWrapper {
  title?: string | Function;
}

const GenericModalWrapper: React.FC<IRecordModalWrapper> = ({
  children,
  title,
}) => {
  return (
    <motion.section
      variants={salesModalVariant}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className={`${styles.wrapper} w-100 position-relative`}
    >
      {title && (
        <div className={`${styles.title} text-center`}>
          {typeof title === "function" ? title() : title}
        </div>
      )}
      {children}
    </motion.section>
  );
};

export default GenericModalWrapper;
