import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAuthSliceDataProps {
  isProductReady: boolean;
  category: null | string;
  isRepli: boolean;
}

const productSliceData: IAuthSliceDataProps = {
  isProductReady: false,
  category: null,
  isRepli: false,
};

const productSlice = createSlice({
  name: "authSlice",
  initialState: productSliceData,
  reducers: {
    handleProductReady: (state, { payload }: PayloadAction<boolean>) => {
      console.log(payload, "from product slice man");
      state.isProductReady = payload;
    },
    handleRep: (state, { payload }: PayloadAction<boolean>) => {
      state.isRepli = payload;
    },

    handleCategory: (state, { payload }: PayloadAction<string>) => {
      state.category = payload;
    },
  },
});

export const { handleProductReady, handleCategory, handleRep } =
  productSlice.actions;

export default productSlice.reducer;
