import axios from "./../../axios.config";

import { getEnvs } from "./../../../utils/getEnvs";

import { saveSession } from "../../../utils/storage";

import { handleAuthentication } from "../../../store/slices/authSlice";

import { getSession } from "../../../utils/storage";

import { checkLocalInfo } from "../checkLocalInfo";

import { ddocProducts } from "./../ddocs";

import { EDDocId } from "../../../models";

const { uri, user, user2 }: any = getEnvs();

export const shoppaGetUser =
  (name: string, navigate: any, dispatch: any) =>
  async (onSucess: (...arg: any) => void) => {
    try {
      const res = await axios.get(`${uri}/_users/org.couchdb.user:${name}`);
      console.log(res, "this is the user man");
      saveSession(user, res?.data);
      saveSession(user2, res?.data);

      shoppaDdoc(navigate);

      onSucess(res?.data, navigate, () => dispatch(handleAuthentication(true)));

      return res;
    } catch (err: any) {
      throw Error("Something went wrong.");
    }
  };

export const shoppaDdoc = async (
  navigate: (arg: string, arg2: any) => void
) => {
  const userInfo: any = getSession(user);
  try {
    checkLocalInfo(userInfo.merchant_name, "Something went wrong!", navigate);

    await axios.put(
      `merchdb-${userInfo.merchant_name}/${EDDocId.product}`,
      ddocProducts
    );
    //await DBConnAdmin(`merchdb-${userInfo.merchant_name}`).putIfNotExists(ddoc);
  } catch (err: any) {
    console.log(err);

    throw new Error("Something went wrong.");
  }
};
