import React from "react";

import { Navigate } from "react-router-dom";

import { useAuthenticate } from "./../../hooks/useAuthenticate";

const Private: React.FC = ({ children }) => {
  const isAuthenticated = useAuthenticate();

  return <>{isAuthenticated ? children : <Navigate to="/login" />}</>;
};

export default Private;
