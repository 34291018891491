import React from "react";

const usePopup = ({ ref, marginY = 0, marginX = 0 }: any) => {
  const [show, setShow] = React.useState(false);

  const [x, setX] = React.useState(0);

  const [y, setY] = React.useState(0);

  const handlePosition = React.useCallback(() => {
    if (ref?.current) {
      setX(+ref.current.getBoundingClientRect().x - marginX);
      setY(+ref.current.getBoundingClientRect().y - marginY);
    }
  }, [ref, marginX, marginY]);

  React.useEffect(() => {
    handlePosition();
    window.addEventListener("resize", handlePosition);
    return () => window.removeEventListener("resize", handlePosition);
  }, [handlePosition]);

  return { show, setShow, x, y };
};

export default usePopup;
