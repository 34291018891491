import React from "react";

import PayAmountBar from "../../../resuable-ui/pay-amount-bar";

import styles from "./index.module.scss";

const Paybar = () => {
  return (
    <section className={`d-flex justify-content-between align-items-center`}>
      <div className={`${styles.pay} mx-3 mx-lg-4 mx-xl-5 mx-xxl-5`}>
        <span>Pay</span>
      </div>
      <div className="flex-fill ">
        <PayAmountBar {...{ amount: 2000 }} />
      </div>
    </section>
  );
};

export default Paybar;
