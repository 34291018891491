import React from "react";

import { EQuerykeys } from "../../../../models/index";

import styles from "./index.module.scss";

import {
  //AnimatePresence,
  motion,
} from "framer-motion";
import {
  //categoryListInnerVariant,
  categoryListVariant,
  //sideMenuVariant,
} from "../../../../utils/anims";
import { categoryListChildrenVariant } from "./../../../../utils/anims/index";
import { useCategory } from "../../../../hooks/useCategory";
import { useQueryClient } from "react-query";

const CategoryList = ({ data, category, setShow, setCategory }: any) => {
  const queryClient = useQueryClient();

  const queryData: any = queryClient.getQueryData(EQuerykeys.product);

  const categories: any = useCategory(queryData);

  const ref = React.useRef("");

  const handlePickCategory = (key: string) => {
    ref.current = key;
    setCategory(key);
    setShow(false);
  };

  return (
    <motion.div
      variants={categoryListVariant}
      animate="visible"
      initial="hidden"
      exit="exit"
      className={`${styles.wrapper1} px-3 py-3`}
    >
      <motion.div
        className={`${styles.wrapper} px-2 px-md-2 px-lg-3 px-xl-3 px-xxl-3 py-3`}
      >
        {!Object.keys(categories).length
          ? "No Available Category"
          : (Object.entries(categories) || []).map(([key, value]: any) => {
              return (
                <motion.div
                  variants={categoryListChildrenVariant}
                  className={`${styles.list}
                  
                  d-flex justify-content-between py-4 px-2`}
                  key={key}
                  onClick={() => handlePickCategory(key)}
                >
                  <div className={styles.name}>
                    <span>{key}</span>
                  </div>
                  <div className={styles.quantity}>
                    <span>
                      {value} item{value > 1 ? "s" : ""}
                    </span>
                  </div>
                </motion.div>
              );
            })}
      </motion.div>
    </motion.div>
  );
};

//${
//                    key === category ? `${styles.active}` : ""
//                  }

export default CategoryList;
