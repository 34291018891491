import { genericMerchantDB } from "./../DBConn";

//import { checkLocalInfo } from "./../checkLocalInfo";

//import { getSession } from "../../../utils/storage";

//import { getEnvs } from "./../../../utils/getEnvs";

import { optsChangesProducts } from "./../opts";

//const { user2 } = getEnvs();

interface IChanges {
  localDBName: string;
  onError: (arg: any) => void;
  onComplete?: (arg: any) => void;
  onChange: (arg: any) => void;
}

export const shoppaChanges = ({
  localDBName,
  onError,
  onComplete,
  onChange,
}: IChanges) => {
  //const userInfo: any = getSession(user2);

  try {
    let changesHandler = genericMerchantDB(localDBName)
      .changes(optsChangesProducts())
      .on("change", onChange)
      .on("error", onError);

    return changesHandler;
  } catch (e) {
    console.log(e, "this is the error man");
    console.log("error from changes", localDBName);
  }
};
