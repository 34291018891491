import React from "react";

import PlaceholderIconSvg from "../../svgs/PlaceholderIconSvg";

import styles from "./index.module.scss";

interface IWalkInList {
  data: Array<any>;
}

const WalkInList = ({ data }: IWalkInList) => {
  return (
    <section className={`${styles.wrapper}`}>
      {data.map((data) => {
        return (
          <div key={data.id} className={`${styles.innerWrapper}`}>
            <div
              className={`d-flex justify-content-between align-items-start ${styles.walkIn}`}
            >
              <div>
                <PlaceholderIconSvg
                  width="40"
                  height="40"
                  viewBox="0 0 50 50"
                />
              </div>
              <div>
                <div>Walk in {data.date}</div>
                <div className={`${styles.reason}`}>{data.reason}</div>
              </div>
              <div>{data.time}</div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default WalkInList;
