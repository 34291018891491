import { toast } from "react-toastify";

type ToastConstraints = "success" | "info" | "error";

interface ITriggerToast {
  type: ToastConstraints;
  message: string;
  duration?: number | number | undefined;
}

export const triggerToast = ({
  type,
  message = "Hello from shoppa app notification",
  duration = 3000,
}: ITriggerToast) => {
  toast[type](message, {
    position: "top-right",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};
