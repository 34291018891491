import axios from "axios";

import { getEnvs } from "./../utils/getEnvs";

const { uri, password, username } = getEnvs();

console.log(password, username, "from here");

const axiosInstance = axios.create({
  baseURL: uri,
  headers: {
    Authorization:
      "Basic " + window.btoa(String(username) + ":" + String(password)),
  },
});

export default axiosInstance;
