import React from "react";

export const MenuIconSvg = () => {
  return (
    <div>
      <svg
        width="25"
        height="17"
        viewBox="0 0 25 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 16.2162H25V13.5135H0V16.2162ZM0 9.45946H25V6.75676H0V9.45946ZM0 0V2.7027H25V0H0Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default MenuIconSvg;
