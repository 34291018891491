import React from "react";
import { IVerification } from "../../../models";

import VerifyIcon from "../../svgs/VerifyIconSvg";

import styles from "./index.module.scss";

import { motion } from "framer-motion";
import { loginVariant, inputVariant } from "./../../../utils/anims/index";

export default function Verification({ text1, text2, text3 }: IVerification) {
  return (
    <div className="col-12 mb-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-7 col-lg-6 col-xl-6 col-xxl-6 d-flex align-items-center">
          <div className={styles.marginRight}>
            <div
              className={`${styles.imgBg} d-flex align-items-center justify-content-center`}
            >
              <VerifyIcon />
            </div>
          </div>

          <motion.div
            variants={loginVariant}
            // initial="hidden"
            // animate="visible"
          >
            <motion.div variants={inputVariant} className={styles.text1}>
              <span>{text1}</span>
            </motion.div>
            {text2 && (
              <motion.div variants={inputVariant} className={styles.text2}>
                <span dangerouslySetInnerHTML={{ __html: text2 }}></span>
              </motion.div>
            )}
            <motion.div variants={inputVariant} className={styles.text3}>
              <span>{text3}</span>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

//  <div className="col-12 mb-4">
//    <div className="row justify-content-between">
//      <div className="col-6 d-flex justify-content-between align-items-center">
//        <div>
//          <img src={VerifyIcon} className="img-fluid" />
//        </div>
//        <div>
//          <div>Reset Passcodssse</div>
//          <div>tip: use a pin you can remember</div>
//        </div>
//      </div>
//    </div>
//  </div>;
