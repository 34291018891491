import React from "react";

import InputBox from "./input-box";
import ButtonPads from "./button-pads";

import { IPinInput } from "./../../models";

import styles from "./index.module.scss";

import { loginVariant } from "./../../utils/anims/index";

import { motion } from "framer-motion";

import useWindowWidth from "./../../hooks/useWindowWidth";

const PinInput = ({ values, setValues, v, setIsEqualPin }: IPinInput) => {
  const length = 5;

  const refs: any = {
    0: React.useRef(null),
    1: React.useRef(null),
    2: React.useRef(null),
    3: React.useRef(null),
    4: React.useRef(null),
  };

  const [current, setCurrent] = React.useState(0);

  React.useEffect(() => {
    focusHandler(0);
    setCurrent(0);
    //eslint-disable-next-line
  }, [v]);

  const focusHandler = (v: number) => refs[v].current && callFocus(v);

  const callFocus = (i: number) => {
    refs[i].current.focus();
    refs[i].current.placeholder = "";
  };

  const click = (i: number, type: string) => {
    if (i) {
      type === "n" && i < length && (() => callFocus(i))();
      type === "b" && i >= 0 && (() => callFocus(i))();
    }
  };

  const handleClear = () => {
    setValues({
      "0": "",
      "1": "",
      "2": "",
      "3": "",
      "4": "",
    });
    focusHandler(0);
    setCurrent(0);
  };

  const handleBackSpace = () => {
    setIsEqualPin(true);

    if (current > 0) {
      if (current <= 1) focusHandler(0);
      setValues((v: any) => ({ ...v, [current]: "" }));
      click(current - 1, "b");
      setCurrent(current - 1);
    }
  };

  const handleClick = (value: number) => {
    setIsEqualPin(true);
    setValues((v: any) => ({ ...v, [current]: value }));
    if (current < 4) {
      click(current + 1, "n");
      setCurrent(current + 1);
      if (current === length - 1) focusHandler(4);
    }
  };

  const { width } = useWindowWidth();

  return (
    <>
      <div className="col-12">
        <div className="row justify-content-center">
          <motion.div
            variants={loginVariant}
            initial="hidden"
            animate="visible"
            className="d-flex justify-content-center col-12 col-md-7 col-lg-6 col-xl-6 col-xxl-6 "
          >
            {Array(length)
              .fill("")
              .map((v, i) => {
                return (
                  <InputBox
                    ref={refs[i]}
                    key={i.toString()}
                    type="text"
                    readOnly={width <= 768 ? true : false}
                    onBlur={(e: any) =>
                      (e.currentTarget.placeholder = "\u2E3B")
                    }
                    data-get-data={i}
                    styleClass={`${
                      values[i] !== ""
                        ? styles.inputNotEmpty
                        : styles.inputEmpty
                    }    
                    ${i === 0 && i === values.length ? "" : "mx-2"}`}
                    value={values[i.toString()]}
                    onClick={(event: any) =>
                      setCurrent(+event.currentTarget.dataset.getData)
                    }
                    placeholder="&#11835;"
                    onChange={(event: any) => {
                      console.log(event.currentTarget);
                      setValues((v: any) => ({
                        ...v,
                        [i]: event?.currentTarget?.value,
                      }));
                      click(+event.currentTarget.dataset.getData + 1, "n");
                    }}
                  />
                );
              })}
          </motion.div>
        </div>
      </div>

      <div className="col-12 mt-4">
        <div className="row justify-content-center">
          <ButtonPads {...{ handleBackSpace, handleClear, handleClick }} />
        </div>
      </div>
    </>
  );
};

export default React.memo(PinInput);
