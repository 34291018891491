import React from "react";

import { motion } from "framer-motion";

import { iconVariant } from "../../utils/anims/index";

const ReportIconSvg = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M12 27V20"
        // stroke="#7F5297"
        strokeWidth="3"
        strokeLinecap="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 1, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
      />
      <motion.path
        d="M18 27V13"
        // stroke="#7F5297"
        strokeWidth="3"
        strokeLinecap="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 0.4, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
      />
      <motion.path
        d="M24 27V15"
        // stroke="#7F5297"
        strokeWidth="3"
        strokeLinecap="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
        }}
      />
    </svg>
  );
};

export default ReportIconSvg;
