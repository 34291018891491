import { EDDocFilterKeys, EDDocPath } from "../../models";
import { IFilter } from "./../../models/index";

import { getSession } from "../../utils/storage";
import { getEnvs } from "./../../utils/getEnvs";

const { user } = getEnvs();

export const productFilter = (): IFilter => {
  const userInfo: any = getSession(user);
  return {
    filter: EDDocPath.product,
    query_params: {
      type: EDDocFilterKeys.product,
      location: userInfo?.location,
      branch: userInfo?.branch,
    },
  };
};

export const optsProducts = (filter: any) => {
  return {
    ...(filter.filter &&
      filter.query_params.type &&
      filter.query_params.location &&
      filter.query_params.branch && {
        filter: filter.filter,
        query_params: filter.query_params,
        live: true,
        retry: true,
        batch_size: 1000,
        batch_limit: 10, 
        back_off_function: function (delay: any) {
          if (delay === 0) return 10 ** 3;
          return delay * 3;
        },
      }),
  };
};

export const optsChangesProducts = () => {
  return {
    live: true,
    since: "now",
    include_docs: true,
  };
};

//export const optsChangesProducts = (filter: any) => {
//  return {
//    ...(filter.filter &&
//      filter.query_params.type &&
//      filter.query_params.location &&
//      filter.query_params.branch && {
//        filter: filter.filter,
//        query_params: filter.query_params,
//        live: true,
//        since: "now",
//        include_docs: true,
//      }),
//  };
//};
