import React from "react";

import styles from "./index.module.scss";

import { motion } from "framer-motion";

import PopUp from "./../../../pop-up/index";
import PopUpPosition from "../../../pop-up/pop-up-position";
import CategoryList from "../category-list";

//import { categoryListData } from "../../../../data/categoryList";

import RightArrowSmSvg from "./../../../svgs/RightArrowSmSvg";

import usePopup from "./../../../../hooks/usePopup";

interface ICategoriesBar {
  setCategory: (arg: string) => void;
  category: string | null;
}

const CatergoriesBar = ({ category, setCategory }: ICategoriesBar) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const { x, y, show, setShow } = usePopup({ ref, marginY: 500 });

  return (
    <>
      <PopUp {...{ show, setShow }}>
        <PopUpPosition
          {...{
            x,
            y,
            width: "350px",
            styleClass: `${styles.shadow} ${styles.rounded}`,
          }}
        >
          <CategoryList {...{ setShow, category, setCategory }} />
        </PopUpPosition>
      </PopUp>

      <section
        ref={ref}
        className={`d-flex mt-4`}
        onClick={() => setShow(!show)}
      >
        <div
          className={`${styles.wrapper} d-flex align-items-center flex-fill p-1`}
        >
          <div className={`${styles.categories} p-2`}>
            <span>Categories</span>
          </div>
          <motion.div
            className={`${styles.w} mx-4 p-1`}
            animate={{
              translateX: "1px",
              scale: 1.5,
              opacity: 0.5,
              transition: {
                repeat: Infinity,
                duration: 5,
                delay: 3,
                ease: "easeInOut",
              },
            }}
          >
            <RightArrowSmSvg />
          </motion.div>
          <div className="text-capitalize">{category}</div>
        </div>
        <div className="mx-5 px-1 px-lg-4 px-xl-5 px-xxl-5"></div>
      </section>
    </>
  );
};

export default CatergoriesBar;
