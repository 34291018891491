import React from "react";

import { IinputField } from "../../../models";
import { inputVariant } from "../../../utils/anims";

import styles from "./index.module.scss";

import { motion } from "framer-motion";

import ErrorIconSvg from "../../svgs/ErrorIconSvg";
import ArrowDown from "../../svgs/ArrowDown";

const renderError = (error: string, showIcon: boolean | undefined) => {
  return (
    <div
      className={`small text-danger px-1 d-flex align-items-center ${
        showIcon && "justify-content-end"
      }`}
    >
      {showIcon && (
        <div className="mx-2">
          <ErrorIconSvg />
        </div>
      )}
      {error}
    </div>
  );
};

export const CustomSelect = React.memo(
  ({
    label,
    password,
    id,
    error,
    className,
    handleShowPassword,
    showIcon,
    name,
    ...props
  }: IinputField) => {
    return (
      <motion.section variants={inputVariant}>
        <div className="text-capitalize">
          {label && <label htmlFor={id}>{label}</label>}
        </div>
        <div className="d-flex align-items-center">
          <input
            className={`${className && className} ${styles.field} ${
              error && props.touched ? styles.error : ""
            }`}
            {...props}
          />
          <span
            className={`${styles.arrow} ${
              error && props.touched ? styles.errorArrow : ""
            }`}
          >
            <ArrowDown />
          </span>
        </div>
        <aside>
          {name === id &&
            error &&
            props.touched &&
            renderError(error, showIcon)}
        </aside>
      </motion.section>
    );
  }
);
