import { getSession } from "../utils/storage";

import { useAppSelector } from "./../store/storeConfig";

import { getEnvs } from "./../utils/getEnvs";

const { user } = getEnvs();

export const useAuthenticate = () => {
  const { authenticated } = useAppSelector((state) => state.authSlice);

  const userInfo = getSession(user);

  console.log(userInfo, authenticated, "this is it man.");

  return authenticated || !!userInfo;
};
