export const checkPinValidity = (arg: any) => Object.values(arg).every(Boolean);

export const convertObjectToString = (v: any) => Object.values(v).join("");

export const checkIsEqualPin = (pin1: any, pin2: any) => {
  return convertObjectToString(pin1) === convertObjectToString(pin2);
};

export const formatAmount = (num: number | string): number | string => {
  const formatted = num.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatted;
};
