import React from "react";

import GiftCardSvg from "../../../svgs/GiftCardSvg";
import PromoIconSvg from "../../../svgs/PromoIconSvg";
import RightArrowIconSvg from "../../../svgs/RightArrowIconSvg";
import CouponIconSvg from "./../../../svgs/CouponIconSvg";

import styles from "./index.module.scss";

import { useNavigate } from "react-router-dom";

const ActionButtons = () => {
  const navigate = useNavigate();

  return (
    <>
      <section
        className={`${styles.wrapper} d-flex justify-content-between align-items-stretch px-0 px-lg-3 px-xl-3 px-xxl-3 `}
      >
        <div className="d-flex flex-fill">
          <button disabled={true}>
            <CouponIconSvg />
            <div>Coupon</div>
          </button>

          <button disabled={true}>
            <GiftCardSvg />
            <div>Giftcard</div>
          </button>

          <button disabled={true}>
            <PromoIconSvg />
            <div>Promo Code</div>
          </button>
        </div>
        <div className="d-flex flex-fill">
          <button
            className="w-100 d-flex align-items-center justify-content-around"
            onClick={() => navigate("/payment")}
          >
            <div>Pay</div>
            <RightArrowIconSvg />
          </button>
        </div>
      </section>
    </>
  );
};

export default ActionButtons;
