import React from "react";

interface IErrorFallbackProps {
  error: any;
  resetErrorBoundary: any;
}

const ErrorFallback = ({ error, resetErrorBoundary }: IErrorFallbackProps) => {
  return (
    <section
      className="col-12 d-flex flex-column align-items-center justify-content-center"
      role="alert"
    >
      <p>Oops! Something got broken.</p>
      <pre>{error.message}</pre>
      <button className="btn btn-sm btn-success" onClick={resetErrorBoundary}>
        Try again
      </button>
    </section>
  );
};

export default ErrorFallback;
