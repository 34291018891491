import React from "react";

import ButtonIconText from "../../../resuable-ui/btn-icon-text";

import RetrieveIcon from "../../../svgs/RetrieveSvgIcon";
import ParkIcon from "../../../svgs/ParkIconSvg";
import DiscardIcon from "../../../svgs/DeleteIconSvg";

import Modal from "../../../resuable-ui/modal";

import { DiscardModalContent } from "../record-modals/discard";
import { ParkModalContent } from "../record-modals/park";
import { RetrieveModalContent } from "../record-modals/retrieve";

enum Etypes {
  park = "park",
  retrieve = "retrieve",
  discard = "discard",
}

const SalesNav = () => {
  const [show, setShow] = React.useState(false);

  const [type, setType] = React.useState<string | null>(null);

  const handleType = (type: string) => {
    setType(type);
    setShow(true);
  };

  return (
    <>
      <Modal
        {...{
          show,
          setShow,
          columnLayout: "col-9 col-lg-8 col-xl-7 col-xxl-6",
        }}
      >
        {type === Etypes.park ? (
          <ParkModalContent />
        ) : type === Etypes.retrieve ? (
          <RetrieveModalContent />
        ) : type === Etypes.discard ? (
          <DiscardModalContent {...{ setShow }} />
        ) : null}
      </Modal>
      <section className="d-flex align-items-center justify-content-between ">
        <ButtonIconText
          disabled={false}
          onClick={() => handleType(Etypes.retrieve)}
        >
          <RetrieveIcon id={Etypes.retrieve} />
          <span className="mx-1">Retrieve Sale</span>
        </ButtonIconText>

        <ButtonIconText
          disabled={false}
          onClick={() => handleType(Etypes.park)}
        >
          <ParkIcon id={Etypes.park} />
          <span className="mx-1" id={Etypes.park}>
            Park Sale
          </span>
        </ButtonIconText>

        <ButtonIconText
          disabled={false}
          onClick={() => handleType(Etypes.discard)}
        >
          <DiscardIcon />
          <span className="mx-1">Discard Sale</span>
        </ButtonIconText>
      </section>
    </>
  );
};

export default SalesNav;
