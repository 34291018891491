import React from "react";

import ReactDOM from "react-dom";

import { IPopUp } from "../../models";

import { motion, AnimatePresence } from "framer-motion";

import styles from "./index.module.scss";

const PopUp: React.FC<IPopUp> = ({
  show,
  withBg = true,
  setShow,
  children,
  ...props
}) => {
  const handleShow = (e: any) => {
    if (e.target.dataset.wrapper) {
      setShow && setShow(false);
    }
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      {show && (
        <motion.section
          className={`position-absolute ${
            withBg ? styles.wrapper : styles.wrapperNoBg
          } w-100`}
          {...props}
          data-wrapper="true"
          onClick={setShow && handleShow}
        >
          <div className="position-relative">{children}</div>
        </motion.section>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default React.memo(PopUp);
