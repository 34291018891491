import React from "react";

import { CustomerDetail } from "./customer-detail";

import styles from "./index.module.scss";

import CartBreakDown from "../../resuable-ui/cart-breakdown";
import Summary from "./summary";

import { cartData } from "./../../../data/cart";

const PaymentSummary = () => {
  return (
    <section
      className={`${styles.wrapper} col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5`}
    >
      <CustomerDetail />

      <Summary {...{ data: cartData }} />

      <CartBreakDown {...{ vat: 15, subTotal: 4656, items: 4, total: 4500 }} />
    </section>
  );
};

export default PaymentSummary;
