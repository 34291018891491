import React from "react";

import { motion } from "framer-motion";

import { iconVariant } from "../../utils/anims/index";

const ActivityIconSvg = () => {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M9 5.2002H4.2C3.35131 5.2002 2.53737 5.53734 1.93726 6.13745C1.33714 6.73757 1 7.5515 1 8.4002V22.8002C1 23.6489 1.33714 24.4628 1.93726 25.0629C2.53737 25.6631 3.35131 26.0002 4.2 26.0002H18.6C19.4487 26.0002 20.2626 25.6631 20.8627 25.0629C21.4629 24.4628 21.8 23.6489 21.8 22.8002V18.0002"
        stroke="#7F5297"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 1.2, ease: "easeInOut" },
          fill: { duration: 1.2, ease: [1, 0, 0.8, 1] },
        }}
      />
      <motion.path
        d="M21.2 11.6C23.851 11.6 26 9.45097 26 6.8C26 4.14903 23.851 2 21.2 2C18.549 2 16.4 4.14903 16.4 6.8C16.4 9.45097 18.549 11.6 21.2 11.6Z"
        stroke="#7F5297"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 3.2, ease: "easeInOut" },
          fill: { duration: 1.2, ease: [1, 0, 0.8, 1] },
        }}
      />
      <motion.path
        d="M6 18.9886L8.99313 15.0984L12.4073 17.7803L15.3364 14"
        stroke="#7F5297"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={iconVariant}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 1.2, ease: [1, 0, 0.8, 1] },
        }}
      />
    </svg>
  );
};

export default ActivityIconSvg;
