import { remoteDB } from "../DBConn";

import { shoppaGetUser } from "./adminService";
import { shoppaGetPincode } from "./pincodeService";

export const shoppaLogin = async (
  name: string,
  password: string,
  navigate: (arg: string) => void,
  dispatch: (arg: boolean) => void
) => {
  try {
    await remoteDB("").logIn(name, password);
    await shoppaGetUser(name, navigate, dispatch)(shoppaGetPincode);
  } catch (err: any) {
    console.log(err);
    throw new Error(err.message);
  }
};
