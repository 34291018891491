export const binarySearch = (arr: Array<any>, docId: string | number) => {
  let low = 0,
    high = arr.length,
    mid;

  while (low < high) {
    mid = (low + high) >>> 1;
    arr[mid]._id < docId ? (low = mid + 1) : (high = mid);
  }
  return low;
};

export const handleDeleted = (docs: Array<any>, id: string | number) => {
  let newDocs = [...docs];
  const index = binarySearch(newDocs, id);
  var doc = newDocs[index];
  if (doc && doc._id === id) {
    newDocs.splice(index, 1);
  }

  return newDocs;
};

export const handleUpdatedOrInserted = (docs: Array<any>, newDoc: any) => {
  let newDocs = [...docs];
  const index = binarySearch(newDocs, newDoc._id);
  const doc = newDocs[index];
  if (doc && doc._id === newDoc._id) {
    newDocs[index] = newDoc;
    return newDocs;
  } else {
    newDocs.splice(index, 0, newDoc);
    return newDocs;
  }
};


//category: "Nintendo Switch";
//category_id: "17202438-a765-4814-b967-de850211e27c";
//category_slug: "gaming-nintendo-switch";
//description: "<p>EA SPORTS™ FIFA 19 delivers a champion-caliber experience on and off the pitch. Led by the prestigious UEFA Champions League, FIFA 19 offers enhanced gameplay features that allow you to control the pitch in every moment. There are new and unrivaled ways to play, including a new mode in the ever-popular FIFA Ultimate Team™ and a new Kick-Off mode experience. Champions Rise in FIFA 19.</p>";
//discountinpercent: 0;
//image: '["https://integral-assets.s3-us-west-2.amazonaws.com/images/nintendo-switch-fifa-19.jpeg"]';
//inventory_status: "In Stock";
//last_modified_dt: "2021-05-01T16:33:31.790875";
//last_modified_dt_epoch: 1619886811.79087;
//price: 19500;
//product_tag: [];
//quantity: 8889877887;
//saleprice: 0;
//slug: "nintendo-switch-fifa-19-soccer";
//tenant_id: "32ef3a6b-05ac-41c3-b35d-037fc9d7823c";
//title: "Nintendo Switch Fifa 19, Soccer";
//type: "Gaming";
//type_id: "04bddebb-b4c2-4df1-a109-1d8d23d7f4fd";
//type_slug: "gaming";
//_id: "18c8aec9-bade-4a92-b5a0-a2d3f089c0d2";
//_rev: "7-edb01a81fe559e5f138dcfb564b8995f";