import { remoteDB, genericMerchantDB } from "./../DBConn";

//import { checkLocalInfo } from "./../checkLocalInfo";

import { getSession } from "../../../utils/storage";

import { getEnvs } from "./../../../utils/getEnvs";

import { IFilter } from "../../../models";

const { user2 } = getEnvs();

interface IReplicate {
  localDBName: string;
  onError: (arg: any) => void;
  onComplete: (arg: any) => void;
  filter: IFilter;
  filterFunc: (arg: any) => any;
  onChange: any;
}

export const shoppaReplicate = ({
  localDBName,
  onError,
  onComplete,
  filter,
  filterFunc,
  onChange,
}: IReplicate) => {
  const userInfo: any = getSession(user2);

  //remoteDB(userInfo?.merchant_name)
  //  .query(EDDocPath.category + "?group=true&group_level=1")
  //  .then((res) => {
  //    console.log(res, "this is it man");
  //  })
  //  .catch((err) => {
  //    console.log(err, "this is an error man from query");
  //  });

  try {
    let replicationHandler = genericMerchantDB(localDBName)
      .replicate.from(remoteDB(userInfo?.merchant_name), filterFunc(filter))
      .on("complete", onComplete)
      .on("error", onError)
      .on("change", onChange);

    return replicationHandler;
  } catch (e) {
    console.log(e, "this is the error man");
    console.log("error from replicator", localDBName);
  }
};
