import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAuthSliceDataProps {
  authenticated: boolean;
}

const authSliceData: IAuthSliceDataProps = {
  authenticated: false,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState: authSliceData,
  reducers: {
    handleAuthentication: (state, { payload }: PayloadAction<boolean>) => {
      state.authenticated = payload;
    },
  },
});

export const { handleAuthentication } = authSlice.actions;

export default authSlice.reducer;
