import React from "react";

const useWindowWidth = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWidth = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    handleWidth();
    window.addEventListener("resize", handleWidth);
    return () => window.removeEventListener("resize", handleWidth);
  }, [width]);

  return { width };
};

export default useWindowWidth;
