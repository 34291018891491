import React from "react";

import styles from "./index.module.scss";
import EditIconSvg from "./../../svgs/EditIconSvg";
import CloseIconSvg from "./../../svgs/CloseIconSvg";

interface ICustomerDetails {
  data: {
    name: string;
    email: string;
    phone: string;
  };
  setEdit?: (arg: boolean) => void;
  handleClear?: (arg: boolean) => void;
}

const SingleCustomerDetail = ({ data }: ICustomerDetails) => {
  return (
    <section className={`${styles.wrapper} flex-fill mx-4`}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div className={styles.name}>{data.name}</div>
          <div>
            <div className={styles.email}>{data.email}</div>
            <div className={styles.phone}>{data.phone}</div>
          </div>
        </div>

        <div className={`d-flex align-self-start ${styles.editIcon}`}>
          <EditIconSvg />
        </div>

        <div className={styles.closeIcon}>
          <CloseIconSvg width="17" height="17" viewBox="0 0 25 25" />
        </div>
      </div>
    </section>
  );
};

export default SingleCustomerDetail;
