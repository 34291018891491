import React from "react";

const KeyPadIconSvg = () => {
  return (
    <svg
      width="30"
      height="41"
      viewBox="0 0 30 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <path
          d="M0.681818 0.5H7.5C7.60042 0.5 7.68182 0.581403 7.68182 0.681818V7.5C7.68182 7.60042 7.60042 7.68182 7.5 7.68182H0.681818C0.581403 7.68182 0.5 7.60042 0.5 7.5V0.681818C0.5 0.581403 0.581403 0.5 0.681818 0.5Z"
          stroke="#B0B0B0"
        />
        <path
          d="M11.5905 0.5H18.4087C18.5091 0.5 18.5905 0.581403 18.5905 0.681818V7.5C18.5905 7.60042 18.5091 7.68182 18.4087 7.68182H11.5905C11.4901 7.68182 11.4087 7.60042 11.4087 7.5V0.681818C11.4087 0.581403 11.4901 0.5 11.5905 0.5Z"
          stroke="#B0B0B0"
        />
        <path
          d="M22.5002 0.5H29.3184C29.4188 0.5 29.5002 0.581403 29.5002 0.681818V7.5C29.5002 7.60042 29.4188 7.68182 29.3184 7.68182H22.5002C22.3998 7.68182 22.3184 7.60042 22.3184 7.5V0.681818C22.3184 0.581403 22.3998 0.5 22.5002 0.5Z"
          stroke="#B0B0B0"
        />
        <path
          d="M0.681818 11.4092H7.5C7.60042 11.4092 7.68182 11.4906 7.68182 11.591V18.4092C7.68182 18.5096 7.60042 18.591 7.5 18.591H0.681818C0.581403 18.591 0.5 18.5096 0.5 18.4092V11.591C0.5 11.4906 0.581403 11.4092 0.681818 11.4092Z"
          stroke="#B0B0B0"
        />
        <path
          d="M11.5905 11.4092H18.4087C18.5091 11.4092 18.5905 11.4906 18.5905 11.591V18.4092C18.5905 18.5096 18.5091 18.591 18.4087 18.591H11.5905C11.4901 18.591 11.4087 18.5096 11.4087 18.4092V11.591C11.4087 11.4906 11.4901 11.4092 11.5905 11.4092Z"
          stroke="#B0B0B0"
        />
        <path
          d="M22.5002 11.4092H29.3184C29.4188 11.4092 29.5002 11.4906 29.5002 11.591V18.4092C29.5002 18.5096 29.4188 18.591 29.3184 18.591H22.5002C22.3998 18.591 22.3184 18.5096 22.3184 18.4092V11.591C22.3184 11.4906 22.3998 11.4092 22.5002 11.4092Z"
          stroke="#B0B0B0"
        />
        <path
          d="M0.681818 22.3184H7.5C7.60042 22.3184 7.68182 22.3998 7.68182 22.5002V29.3184C7.68182 29.4188 7.60042 29.5002 7.5 29.5002H0.681818C0.581403 29.5002 0.5 29.4188 0.5 29.3184V22.5002C0.5 22.3998 0.581403 22.3184 0.681818 22.3184Z"
          stroke="#B0B0B0"
        />
        <path
          d="M11.5905 22.3184H18.4087C18.5091 22.3184 18.5905 22.3998 18.5905 22.5002V29.3184C18.5905 29.4188 18.5091 29.5002 18.4087 29.5002H11.5905C11.4901 29.5002 11.4087 29.4188 11.4087 29.3184V22.5002C11.4087 22.3998 11.4901 22.3184 11.5905 22.3184Z"
          stroke="#B0B0B0"
        />
        <path
          d="M11.5905 33.2271H18.4087C18.5091 33.2271 18.5905 33.3085 18.5905 33.4089V40.2271C18.5905 40.3275 18.5091 40.4089 18.4087 40.4089H11.5905C11.4901 40.4089 11.4087 40.3275 11.4087 40.2271V33.4089C11.4087 33.3085 11.4901 33.2271 11.5905 33.2271Z"
          stroke="#B0B0B0"
        />
        <path
          d="M22.5002 22.3184H29.3184C29.4188 22.3184 29.5002 22.3998 29.5002 22.5002V29.3184C29.5002 29.4188 29.4188 29.5002 29.3184 29.5002H22.5002C22.3998 29.5002 22.3184 29.4188 22.3184 29.3184V22.5002C22.3184 22.3998 22.3998 22.3184 22.5002 22.3184Z"
          stroke="#B0B0B0"
        />
      </g>
    </svg>
  );
};

export default KeyPadIconSvg;
