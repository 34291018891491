import React from "react";

const PromoIconSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.48936 21.8298V27.2553L10.9149 25.9149L15 30L19.0851 25.9149L24.5106 27.2553V21.8298L30 20.4255L27.2553 15L30 9.57447L24.5106 8.17021V2.74468L19.0851 4.08511L15 0L10.9149 4.08511L5.48936 2.74468V8.17021L0 9.57447L2.74468 15L0 20.4255L5.48936 21.8298ZM5.17021 13.7872L3.95745 11.2979L6.12766 10.7872C6.76596 10.6596 7.26596 10.3511 7.62766 9.8617C7.98936 9.37234 8.17021 8.80851 8.17021 8.17021V6.25532L10.2128 6.82979H10.9149C11.6383 6.82979 12.2766 6.55319 12.8298 6L15 3.82979L17.1702 6C17.7234 6.55319 18.3617 6.82979 19.0851 6.82979C19.3404 6.82979 19.5745 6.78723 19.7872 6.70213L21.8298 6.25532V8.17021C21.8298 8.80851 22.0106 9.37234 22.3723 9.8617C22.734 10.3511 23.234 10.6596 23.8723 10.7872L26.0426 11.2979L24.8298 13.7872C24.4043 14.5957 24.4043 15.4043 24.8298 16.2128L26.0426 18.7021L23.8723 19.2128C23.234 19.3404 22.734 19.6489 22.3723 20.1383C22.0106 20.6277 21.8298 21.1915 21.8298 21.8298V23.7447L19.7872 23.1702H19.0851C18.3617 23.1702 17.7234 23.4468 17.1702 24L15 26.1702L12.8298 24C12.2766 23.4468 11.6383 23.1702 10.9149 23.1702C10.6596 23.1702 10.4255 23.2128 10.2128 23.2979L8.17021 23.7447V21.8298C8.17021 21.1915 7.98936 20.6277 7.62766 20.1383C7.26596 19.6489 6.76596 19.3404 6.12766 19.2128L3.95745 18.7021L5.17021 16.2128C5.59574 15.4043 5.59574 14.5957 5.17021 13.7872ZM13.6596 19.6596L20.0426 13.2128C20.6383 12.617 20.6383 11.9787 20.0426 11.2979C19.4043 10.7447 18.766 10.7447 18.1277 11.2979L13.6596 15.8298L11.8723 14.0426C11.234 13.4894 10.5957 13.4894 9.95745 14.0426C9.40425 14.6809 9.40425 15.3191 9.95745 15.9574L13.6596 19.6596Z"
        fill="white"
      />
    </svg>
  );
};

export default PromoIconSvg;
