import React from "react";
import SearchIconSvgSm from "../../../svgs/SearchIconSvgSm";

import styles from "./index.module.scss";

interface ISearchBar {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
}

export const SearchBar = ({ onChange, value }: ISearchBar) => {
  return (
    <section
      className={`${styles.wrapper} d-flex align-items-center flex-fill p-2`}
    >
      <aside className="">
        <SearchIconSvgSm />
      </aside>

      <aside className="mx-3 flex-fill">
        <input
          autoCorrect="false"
          spellCheck="false"
          placeholder="Scan or Search"
          type="text"
          value={value}
          onChange={onChange}
          className=" w-100"
        />
      </aside>
    </section>
  );
};
