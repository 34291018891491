import React from "react";

import Button from "./../button/index";

import { IconfirmationBtn } from "./../../../models/index";

import styles from "./index.module.scss";

import SettingIconSvg from "./../../svgs/SettingIcon";

const ConfirmationBtn = ({
  handleClick,
  text,
  handleBack,
  showBackBtn,
}: IconfirmationBtn) => {
  return (
    <div>
      <div className="col-12 mt-4">
        <div className="row justify-content-center position-relative">
          <div className="col-12 mt-3 d-flex flex-column flex-md-row flex-lg-row flex-xl-row flex-xxl-row column-reverse justify-content-center align-items-center position-relative">
            {showBackBtn && (
              <div
                onClick={handleBack}
                className={`${styles.back} position-absolute d-flex`}
              >
                <SettingIconSvg />
                <span className="mx-2 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                  Go back
                </span>
              </div>
            )}
            <Button
              type="submit"
              onClick={() => handleClick()}
              disabled={false}
              styles="py-3 px-5"
            >
              {text}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationBtn;
