import PouchDB from "pouchdb";

import { getSession } from "../../utils/storage";

import { getEnvs } from "./../../utils/getEnvs";

export const remoteDB = (name: string) => {
  const { uri } = getEnvs();
  console.log(name, "name");
  return new PouchDB(`${uri}${name && `/merchdb-${name}`}`, {
    skip_setup: true,
  });
};

export const localDB = (name: string) => {
  const { user } = getEnvs();

  const userInfo: any = getSession(user);

  return new PouchDB(
    `${
      name && userInfo.merchant_name
        ? `${userInfo.merchant_name}-${userInfo.name}-${name}`
        : "error-db"
    }`,
    {
      skip_setup: true,
    }
  );
};

export const genericMerchantDB = (name: string) => {
  const { user } = getEnvs();

  const userInfo: any = getSession(user);

  return new PouchDB(`${userInfo.merchant_name}-${userInfo.branch}-${name}`, {
    skip_setup: true,
  });
};

export const cartDB = (name: string) => {
  const { user } = getEnvs();

  const userInfo: any = getSession(user);

  return new PouchDB(
    `${userInfo.merchant_name}-${userInfo.branch}-${userInfo.name}-${name}`,
    {
      skip_setup: true,
    }
  );
};

//export const DBConnAdmin = (path: string) => {
//  const { uri, username, password } = getEnvs();

//  return new PouchDB(`${uri}/${path}`, {
//    skip_setup: true,
//    auth: {
//      username: username,
//      password: password,
//    },
//  });
//};

//export const remoteDB = (name: string) => {
//  const { uri } = getEnvs();
//  console.log(name, "name");
//  return new PouchDB(`${uri}${name && `/merchdb-${name}`}`, {
//    skip_setup: true,
//    fetch: (url, opts: any) => {
//      opts.credentials = "include";
//      return PouchDB.fetch(url, opts);
//    },
//  });
//};

//export const localDB = (name: string) => {
//  const { user } = getEnvs();

//  const userInfo: any = getSession(user);

//  return new PouchDB(
//    `${
//      name && userInfo.merchant_name
//        ? `${userInfo.merchant_name}-${userInfo.name}-${name}`
//        : "error-db"
//    }`,
//    {
//      skip_setup: true,
//      fetch: (url, opts: any) => {
//        opts.credentials = "include";
//        return PouchDB.fetch(url, opts);
//      },
//    }
//  );
//};
