import { EDDocId, EDDocFilterKeys, EDDocMapKeys } from "./../../models";

//export const ddoc = {
//  _id: EDDoc.productFilterId,
//  filters: {
//    filter: function (doc: any, req: any) {
//      if (doc.location && doc.type && doc.branch) {
//        return (
//          doc.type === req.query.type &&
//          doc.location === req.query.location &&
//          doc.branch === req.query.branch
//        );
//      } else {
//        if (doc.type) {
//          return doc.type === req.query.type;
//        }
//      }
//    }.toString(),
//  },
//};

export const ddocProducts = {
  _id: EDDocId.product,
  type: "ddoc",
  filters: {
    [EDDocFilterKeys.product]: function (doc: any, req: any) {
      if (doc.location && doc.type && doc.branch) {
        return (
          doc.type === req.query.type &&
          doc.location === req.query.location &&
          doc.branch === req.query.branch
        );
      }
    }.toString(),
  },

  views: {
    [EDDocMapKeys.category]: {
      map: `function (doc) {
      
        if(doc.category) {
          emit(doc.category, 1);
        }
      }`,
      reduce: "_sum",
    },
  },
};

//export const ddocProducts = {
//  _id: EDDocId.product,
//  type: "ddoc",
//  filters: {
//    [EDDocFilterKeys.product]: function (doc: any, req: any) {
//      if (doc.type === "ddoc") return true;
//      if (doc.location && doc.type && doc.branch) {
//        return (
//          doc.type === req.query.type &&
//          doc.location === req.query.location &&
//          doc.branch === req.query.branch
//        );
//      }
//    }.toString(),
//  },

//  views: {
//    [EDDocMapKeys.category]: {
//      map: `function (doc) {

//        if(doc.category) {
//          emit(doc.category, 1);
//        }
//      }`,
//      reduce: "_sum",
//    },
//  },
//};
