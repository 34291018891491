import * as React from "react";

import { motion, AnimatePresence } from "framer-motion";

import styles from "./index.module.scss";
import { accordionVariant } from "../../../utils/anims";

type TAccordion = {
  headerContext?: any;
  bodyContext?: any;
  id: number;
  expanded: boolean | number;
  setExpanded: (arg: false | number) => void;
};

const Accordion = ({
  id,
  expanded,
  //setExpanded,
  headerContext,
  bodyContext,
}: TAccordion) => {
  const isOpen = id === expanded;

  return (
    <section className={`${styles.wrapper}`}>
      <motion.header
        className={styles.header}
        initial={false}
        animate={{
          backgroundColor: isOpen ? "rgba(127, 82, 151, 0.1)" : "#ffffff",
          borderRadius: isOpen ? "10px" : "",
          border: isOpen ? "1px solid #dddddd" : "",
        }}
        //onClick={() => setExpanded(isOpen ? false : i)}
      >
        {headerContext(isOpen)}
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            className="my-1"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={accordionVariant}
            transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {bodyContext()}
          </motion.section>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Accordion;
