export const useCategory = (data: Array<any>) => {
  const categories = (data || [])?.reduce((a: any, b: any) => {
    const category = b.category;
    if (!a.hasOwnProperty(category)) {
      a[category] = 1;
    } else {
      a[category] = a[category] + 1;
    }

    return a;
  }, {});

  const all = data.length;

  return { all, ...categories };
};

//export const useCategory = () => {
//  const mapCategories = new Map();
//  for (const { category } of data) {
//    if (!mapCategories.has(category)) {
//      mapCategories.set(category, 1);
//    } else {
//      const currentCount = mapCategories.get(category) + 1;
//      mapCategories.set(category, currentCount);
//    }
//  }
//  return mapCategories;
//};

//{
//  "_id": "org.couchdb.user:peter",
//  "_rev": "1-8534376226a1896b4d8885037a939d98",
//  "type": "user",
//  "name": "peter",
//  "location": "lagos",
//  "roles": [
//    "macaroniman:owner"
//  ],
//  "branch": "lekki",
//  "merchant_name": "macaroniman",
//  "firstName": "Peter",
//  "lastName": "Maiyaki",
//  "userName": "petershola@gmail.com",
//  "role": "owner",
//  "password_scheme": "pbkdf2",
//  "iterations": 10,
//  "derived_key": "28f55fbd5a8e12011d8c61001518876d041bf13b",
//  "salt": "e2c22df712e40aecb19615469357f192"
//}
