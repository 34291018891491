import React from "react";
import { ITextBox } from "../../../models";

import styles from "./index.module.scss";

const TextBox: React.FC<ITextBox> = ({ children, styleName, ...props }) => {
  return (
    <div className={`${styles.wrapper} ${styleName}`} {...props}>
      {children}
    </div>
  );
};

export default TextBox;
