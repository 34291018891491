import React from "react";

//import { categoryListVariant } from "../../../../utils/anims";

import styles from "./index.module.scss";

import { motion } from "framer-motion";

export const ProductListWrapper: React.FC = ({ children }) => {
  return (
    <motion.section
      //variants={categoryListVariant}
      //animate="visible"
      //initial="hidden"
      //exit="exit"
      className={`${styles.wrapper} mt-4`}
    >
      <section
        className={`${styles.container} container-fluid overflow-hidden`}
      >
        {children}
      </section>
    </motion.section>
  );
};

export default ProductListWrapper;
