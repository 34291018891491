const {
  REACT_APP_COUCHDB_URI: uri,
  REACT_APP_SHOPPA_ADMIN_NAME: username,
  REACT_APP_SHOPPA_ADMIN_PASSWORD: password,
  REACT_APP_USER: user,
  REACT_APP_USER2: user2,
  REACT_APP_ENC_SECRET: secret,
} = process.env;

export const getEnvs = () => ({ uri, username, password, user, user2, secret });
