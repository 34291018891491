import React from "react";

import { DEFAULT_IMAGE_BASE64 } from "../../../../../../utils/msc";

import styles from "./index.module.scss";

import { useAddToCart } from "./../../../../../../integrations/react_query/mutations";

const GridList = ({ product, handleAddToCart }: any) => {
  return (
    <section
      onClick={() => handleAddToCart(product)}
      className={`${styles.wrapper} d-flex col-6 col-sm-4 col-md-6 col-lg-6 col-xl-4 col-xxl-4 my-2`}
    >
      <div className={`${styles.innerWrapper} p-2`}>
        <div className={`${styles.imgBg} px-4`}>
          <img
            alt={product?.name}
            src={product?.image || DEFAULT_IMAGE_BASE64}
            className="img-fluid"
          />
        </div>

        <div className="text-center px-1 my-2">
          <aside className={styles.title}>{product?.name}</aside>
          <aside className={styles.desc}>{product?.description}</aside>
        </div>
      </div>
    </section>
  );
};

export default GridList;
