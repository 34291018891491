import React from "react";

import { useNavigate } from "react-router-dom";

import { useAuthenticate } from "./useAuthenticate";

import { useQueryClient } from "react-query";

import { shoppaChanges } from "./../integrations/pouch/services/changesService";

interface IDBChanges {
  localDBName: string;
  qClient: (...args: any) => void;
  shouldBeginChange: boolean;
}

export const useDBChanges = ({
  localDBName,
  qClient,
  shouldBeginChange,
}: IDBChanges) => {
  const navigate = useNavigate();

  const changesRef = React.useRef<any>(null);

  const isAuthenticated = useAuthenticate();

  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (isAuthenticated) {
      if (shouldBeginChange) {
        changesRef.current = shoppaChanges({
          localDBName,
          onChange: (change) => {
            console.log("i am receiving change man");
            qClient(queryClient, change);
          },
          onError: (info) => {
            console.log("an error occured man from changes", info, localDBName);
          },
        });
      }
      return () => changesRef.current && changesRef.current.cancel();
    } else {
      navigate("/login", { replace: true });
    }

    //eslint-disable-next-line
  }, [isAuthenticated, localDBName, queryClient, shouldBeginChange]);
};
