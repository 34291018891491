import React from "react";

const BackIconSvg = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.524 9.73058H5.03684L12.2398 2.5276C12.8155 1.95195 12.8155 1.0073 12.2398 0.431653C12.1033 0.294821 11.9411 0.186263 11.7625 0.112194C11.5839 0.0381252 11.3925 0 11.1992 0C11.0059 0 10.8145 0.0381252 10.6359 0.112194C10.4574 0.186263 10.2952 0.294821 10.1586 0.431653L0.431654 10.1586C0.294821 10.2952 0.186264 10.4574 0.112195 10.6359C0.0381259 10.8145 0 11.0059 0 11.1992C0 11.3925 0.0381259 11.5839 0.112195 11.7625C0.186264 11.9411 0.294821 12.1033 0.431654 12.2398L10.1586 21.9668C10.2953 22.1034 10.4575 22.2118 10.6361 22.2858C10.8146 22.3597 11.006 22.3978 11.1992 22.3978C11.3925 22.3978 11.5838 22.3597 11.7624 22.2858C11.9409 22.2118 12.1032 22.1034 12.2398 21.9668C12.3765 21.8301 12.4849 21.6679 12.5588 21.4894C12.6328 21.3108 12.6708 21.1194 12.6708 20.9262C12.6708 20.7329 12.6328 20.5416 12.5588 20.363C12.4849 20.1845 12.3765 20.0222 12.2398 19.8856L5.03684 12.6826H21.524C22.3358 12.6826 23 12.0184 23 11.2066C23 10.3948 22.3358 9.73058 21.524 9.73058Z"
        fill="white"
      />
    </svg>
  );
};

export default BackIconSvg;
