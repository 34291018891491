import React from "react";

import styles from "./index.module.scss";

import SingleCustomerDetail from "../../../resuable-ui/single-customer-detail";

import PlaceholderIconSvg from "../../../svgs/PlaceholderIconSvg";

export const CustomerDetail = () => {
  const [data] = React.useState({
    name: "Bernard Kelechi",
    email: "Bernardkeecee@ymail.com",
    phone: "+234 (0) 703 345 3748",
  });

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${styles.wrapper}`}
    >
      <div>
        <PlaceholderIconSvg width="40" height="40" viewBox="0 0 50 50" />
      </div>
      <SingleCustomerDetail {...{ data }} />
    </div>
  );
};
