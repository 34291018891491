import React from "react";

import { WeatherIcon } from "../../exports/asset";

import styles from "./index.module.scss";

import { INavBar } from "./../../models/index";

import MenuIconSvg from "../svgs/MenuIconSvg";
import { PlaceholderIconSvg } from "../svgs/PlaceholderIconSvg";
import BackIconSvg from "../svgs/BackIconSvg";

import { useNavigate } from "react-router-dom";

import { getSession } from "../../utils/storage";
import { getEnvs } from "./../../utils/getEnvs";

import { removeSession } from "../../utils/storage";

const Navbar = ({ navState, setNavState, showBar }: INavBar) => {
  const navigate = useNavigate();

  const { user: shoppaUser } = getEnvs();

  const user: any = getSession(shoppaUser);

  const userRole = user?.roles?.length === 1 ? user?.role : "admin";

  return (
    <>
      <section
        className={`d-flex flex-column flex-md-row flex-lg-row flex-xl-row flex-xxl-row align-items-center justify-content-between px-5 py-1 ${styles.wrapper} `}
      >
        {showBar ? (
          <div className="d-flex align-items-center">
            <aside
              className={`${styles.menu}`}
              onClick={() => setNavState(!navState)}
            >
              <MenuIconSvg />
            </aside>
            <aside className={`mx-4 ${styles.icon}`}>
              <span>Shoppa</span>
            </aside>
          </div>
        ) : (
          <div onClick={() => navigate(-1)} className={styles.cursor}>
            <BackIconSvg />
          </div>
        )}

        <div>
          <img alt="weather icon" src={WeatherIcon} />
          <span className={`${styles.weatherText} mx-2`}>
            Cloudy with a chance of meatballs at 25 C
          </span>
        </div>

        <button
          className="btn btn-sm btn-danger"
          onClick={() => {
            console.log("clicking");
            removeSession(shoppaUser);
            window.location.href = "/login";
          }}
        >
          logout
        </button>
        <div className="d-flex align-items-center">
          <div className={`${styles.grWrapper} mx-2`}>
            <aside className={`${styles.greeting}`}>
              <span>
                Hello,{" "}
                {user?.firstName && user?.lastName
                  ? `${user?.firstName} ${user?.lastName}`
                  : user?.name}
              </span>
            </aside>
            <aside className={`${styles.position}`}>
              <span>{userRole}</span>
            </aside>
          </div>

          <div className="">
            <PlaceholderIconSvg width="40" height="40" viewBox="0 0 50 50" />
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Navbar);
