import React from "react";

export const PlaceholderIconSvg = (props: any) => {
  return (
    <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 0C11.2 0 0 11.2 0 25C0 38.8 11.2 50 25 50C38.8 50 50 38.8 50 25C50 11.2 38.8 0 25 0ZM25 7.5C29.15 7.5 32.5 10.85 32.5 15C32.5 19.15 29.15 22.5 25 22.5C20.85 22.5 17.5 19.15 17.5 15C17.5 10.85 20.85 7.5 25 7.5ZM25 43C22.0296 43 19.1055 42.2649 16.4882 40.8603C13.8709 39.4557 11.6419 37.4253 10 34.95C10.075 29.975 20 27.25 25 27.25C29.975 27.25 39.925 29.975 40 34.95C38.3581 37.4253 36.1291 39.4557 33.5118 40.8603C30.8945 42.2649 27.9704 43 25 43Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};

export default PlaceholderIconSvg;
