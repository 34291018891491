import React from "react";

import CompleteTransaction from "../complete-transaction";
import Paybar from "./pay-bar";
import PaymentButtons from "./payment-buttons";

import styles from "./index.module.scss";
//import Receipt from "../receipt";
//import { cartData } from "./../../../data/cart";

export const PaymentOptions = () => {
  const isPaid = false;

  return (
    <section
      className={`${styles.wrapper} position-relative col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7`}
    >
      {isPaid ? (
        <>
          <CompleteTransaction />

          {/*<Receipt {...{ data: cartData }} />*/}
        </>
      ) : (
        <>
          <Paybar />
          <PaymentButtons />
        </>
      )}
    </section>
  );
};

export default PaymentOptions;
