import React from "react";

import styles from "./index.module.scss";

interface IAccountDetails {
  name: string;
  number: string;
}

const AccountDetails = ({ name, number }: IAccountDetails) => {
  return (
    <section className={`${styles.wrapper} p-2 w-50`}>
      <div className={styles.name}>{name}</div>
      <div className={styles.number}>{number}</div>
    </section>
  );
};

export default AccountDetails;
