import React from "react";

//import { ListToggle } from "../list-toggle";
import { SearchBar } from "./../search-bar/index";

import { IProductNav } from "./../../../../models/index";
import { GridToggle } from "./../grid-toggle/index";

import GridIconSvg from "../../../svgs/GridIconSvg";
import ListIconSvg from "../../../svgs/ListToggleIconSvg";

const ProductNav = ({
  toggle,
  setToggle,
  onChange,
  value,
}: IProductNav) => {
  return (
    <section className="d-flex align-items-center">
      <SearchBar {...{ onChange, value, }} />

      <div>
        {toggle ? (
          <GridToggle onClick={() => setToggle(!toggle)}>
            <ListIconSvg />
          </GridToggle>
        ) : (
          <GridToggle onClick={() => setToggle(!toggle)}>
            <GridIconSvg />
          </GridToggle>
        )}
      </div>
    </section>
  );
};

export default React.memo(ProductNav);
