import React from "react";

import PinInput from "../components/pin-input";
import ConfirmationBtn from "../components/resuable-ui/confirm-btn";
import Verification from "../components/resuable-ui/verification";

import { useNavigate } from "react-router-dom";
import { encDecrypt } from "../utils/enc";
import { usePinCode } from "../integrations/react_query/mutations";
import { IPincode } from "../models";
import { getSession } from "../utils/storage";
import { getEnvs } from "./../utils/getEnvs";

import {
  checkPinValidity,
  convertObjectToString,
  checkIsEqualPin,
} from "../utils/funcs";

const { user: shoppaUser } = getEnvs();

const Reset = () => {
  const navigate = useNavigate();

  const [values, setValues] = React.useState({
    "0": "",
    "1": "",
    "2": "",
    "3": "",
    "4": "",
  });

  const [confirmValues, setConfirmValues] = React.useState({
    "0": "",
    "1": "",
    "2": "",
    "3": "",
    "4": "",
  });

  const [isConfirm, setIsConfirm] = React.useState(false);


  const handleGoToConfirmScreen = () => {
    checkPinValidity(values) && setIsConfirm(true);
  };

  const [isEqualPin, setIsEqualPin] = React.useState(true);

  const { mutate, isLoading } = usePinCode();

  const userInfo: any = getSession(shoppaUser);

  const handlePinProcess = () => {
    if (checkPinValidity(confirmValues)) {
      if (checkIsEqualPin(values, confirmValues)) {
        console.log("pins are correct man");
        const password = encDecrypt.encrypt(convertObjectToString(values));
        console.log(userInfo);
        console.log(userInfo?.userName, "this is the user email man");

        const data: IPincode = {
          _id: `_local/${userInfo?.userName}`,
          password,
        };
        mutate(data);
      } else {
        setIsEqualPin(false);
        console.log("pins are not correct man.");
      }
      console.log("I am processing your pin");
    } else {
      console.log("i am not processing  your pin");
    }
  };

  const renderError = (arg: boolean, message: string) => (
    <>
      {!arg && (
        <div className="col-12 text-center mb-3 text-danger fw-bold">
          {message}
        </div>
      )}
    </>
  );

  return (
    <section className="container">
      <section
        className="row justify-content-center align-items-center overflow-hidden"
        style={{ height: "100vh" }}
      >
        <section className="col-12">
          <section className="row justify-content-center">
            {!isConfirm ? (
              <>
                <Verification
                  {...{
                    text1: "Reset Passcode",
                    text3: "tip: use a pin you can remember",
                  }}
                />

                <PinInput {...{ values, setValues, v: false, setIsEqualPin }} />

                <ConfirmationBtn
                  {...{
                    handleClick: () => handleGoToConfirmScreen(),
                    text: "Continue",
                    handleBack: () => navigate(-1),
                    showBackBtn: false,
                  }}
                />
              </>
            ) : (
              <>
                <Verification
                  {...{
                    text1: "Re-enter New PIN",
                    text2:
                      "You are advised to personalize your PIN. <br> You can reset forgotten password or contact Admin.",
                    text3: "tip: Use a familiar number",
                  }}
                />

                {renderError(isEqualPin, "Pins do not match. Try Again.")}

                <PinInput
                  {...{
                    values: confirmValues,
                    setValues: setConfirmValues,
                    v: true,
                    setIsEqualPin,
                  }}
                />

                <ConfirmationBtn
                  {...{
                    handleClick: () => handlePinProcess(),
                    text: `${isLoading ? "Confirming..." : "Confirm"}`,
                    handleBack: () => setIsConfirm(false),
                    showBackBtn: true,
                  }}
                />
              </>
            )}
          </section>
        </section>
      </section>
    </section>
  );
};

export default Reset;
