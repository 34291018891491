import React from "react";

import styles from "./index.module.scss";

import { Formik, Form, FormikHelpers, FormikProps } from "formik";

import * as Yup from "yup";

import { InputField } from "../../../resuable-ui/input";
import { CustomSelect } from "../../../resuable-ui/custom-select-field/index";

import PopUp from "../../../pop-up";
import PopUpPosition from "../../../pop-up/pop-up-position";

import { CustomSelectDropDown } from "../../../resuable-ui/custom-select-dropdown";
import Button from "./../../../resuable-ui/button/index";

import { stateLgs } from "../../../../data/stateLgs";
import states from "../../../../data/states";

import useWindowWidth from "../../../../hooks/useWindowWidth";
import usePopup from "../../../../hooks/usePopup";

import { motion } from "framer-motion";
import { addCustomerVariant } from "../../../../utils/anims";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name required"),
  lastName: Yup.string().required("Last name required"),
  email: Yup.string().email("Email is not valid").required("Email required"),
  phone: Yup.string().required("Phone required"),
  state: Yup.string().required("State required"),
  city: Yup.string().required("City required"),
});

type TUserValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  state: string;
  city: string;
};

const initialValues: TUserValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  state: "",
  city: "",
};

const CustomerForm = () => {
  const onSubmit = (
    values: TUserValues,
    actions: FormikHelpers<TUserValues>
  ) => {
    console.log("submission of form", values, actions, "from formik man");
  };

  const stateRef = React.useRef(null);

  const cityRef = React.useRef(null);

  const { width } = useWindowWidth();

  const {
    x,
    y,
    show: showState,
    setShow: setShowState,
  } = usePopup({
    ref: stateRef,
    marginX: width <= 1000 ? 50 : -250,
    marginY: width <= 1000 ? 300 : 280,
  });

  const {
    x: x2,
    y: y2,
    show: showCity,
    setShow: setShowCity,
  } = usePopup({
    ref: cityRef,
    marginX: width <= 1000 ? 50 : -250,
    marginY: width <= 1000 ? 300 : 280,
  });

  return (
    <>
      <motion.section
        variants={addCustomerVariant}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={`${styles.wrapper} w-100 text-center position-relative`}
      >
        <div className={`${styles.title} text-center`}>Add new customer</div>

        <Formik {...{ initialValues, validationSchema, onSubmit }}>
          {(props: FormikProps<TUserValues>) => {
            return (
              <Form>
                <div className="d-flex align-items-start justify-content-between">
                  <div className="flex-fill">
                    <InputField
                      onBlur={props.handleBlur}
                      id="firstName"
                      onChange={props.handleChange}
                      type="text"
                      value={props.values.firstName}
                      error={props.errors.firstName}
                      touched={props.touched.firstName}
                      placeholder="First Name"
                      name="firstName"
                      showIcon={true}
                    />
                  </div>
                  <div className="flex-fill mx-3 mx-lg-4 mx-xl-4 mx-xxl-4">
                    <InputField
                      onBlur={props.handleBlur}
                      id="lastName"
                      onChange={props.handleChange}
                      type="text"
                      value={props.values.lastName}
                      error={props.errors.lastName}
                      touched={props.touched.lastName}
                      placeholder="Last Name"
                      name="lastName"
                      showIcon={true}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-start justify-content-between my-4 my-lg-5 my-xl-5 my-xxl-5">
                  <div className="flex-fill">
                    <InputField
                      onBlur={props.handleBlur}
                      id="email"
                      onChange={props.handleChange}
                      type="text"
                      value={props.values.email}
                      error={props.errors.email}
                      touched={props.touched.email}
                      placeholder="Email"
                      name="email"
                      showIcon={true}
                    />
                  </div>
                  <div className="flex-fill mx-3 mx-lg-4 mx-xl-4 mx-xxl-4">
                    <InputField
                      onBlur={props.handleBlur}
                      id="phone"
                      onChange={props.handleChange}
                      type="text"
                      value={props.values.phone}
                      error={props.errors.phone}
                      touched={props.touched.phone}
                      placeholder="Phone"
                      name="phone"
                      showIcon={true}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-start justify-content-between my-3 my-lg-5 my-xl-5 my-xxl-5">
                  <div
                    className="flex-fill"
                    ref={stateRef}
                    onClick={() => setShowState(true)}
                  >
                    <CustomSelect
                      onBlur={props.handleBlur}
                      id="state"
                      onChange={props.handleChange}
                      type="text"
                      value={props.values.state}
                      error={props.errors.state}
                      touched={props.touched.state}
                      placeholder="State"
                      name="state"
                      showIcon={true}
                      readOnly={true}
                    />
                  </div>
                  <div
                    className="flex-fill mx-3 mx-lg-4 mx-xl-4 mx-xxl-4"
                    ref={cityRef}
                    onClick={() => props.values.state && setShowCity(true)}
                  >
                    <CustomSelect
                      onBlur={props.handleBlur}
                      id="city"
                      onChange={props.handleChange}
                      type="text"
                      value={props.values.city}
                      error={props.errors.city}
                      touched={props.touched.city}
                      placeholder="City"
                      name="city"
                      showIcon={true}
                      readOnly={true}
                      className={!props.values.state && styles.cursorDisabled}
                    />
                  </div>
                </div>

                <Button
                  type="submit"
                  disabled={false}
                  styles="w-25 py-3 py-lg-3 py-xl-4 py-xxl-4"
                >
                  Save
                </Button>

                <PopUp
                  {...{
                    show: showState,
                    setShow: setShowState,
                    withBg: false,
                  }}
                >
                  <PopUpPosition
                    {...{
                      x,
                      y,
                      width: "224px",
                      styleClass: `${styles.shadow} ${styles.rounded}`,
                    }}
                  >
                    <CustomSelectDropDown
                      {...{
                        type: "state",
                        setShow: setShowState,
                        title: "Select State",
                        data: states,
                        props,
                        setSelected: props.setFieldValue,
                        clearField: () => props.setFieldValue("city", ""),
                        currentValue: props.values.state,
                      }}
                    />
                  </PopUpPosition>
                </PopUp>

                <PopUp
                  {...{ show: showCity, setShow: setShowCity, withBg: false }}
                >
                  <PopUpPosition
                    {...{
                      x: x2,
                      y: y2,
                      width: "224px",
                      styleClass: `${styles.shadow} ${styles.rounded}`,
                    }}
                  >
                    <CustomSelectDropDown
                      {...{
                        title: "Select City",
                        setShow: setShowCity,
                        data: stateLgs?.[props.values.state],
                        props,
                        type: "city",
                        setSelected: props.setFieldValue,
                        currentValue: props.values.city,
                      }}
                    />
                  </PopUpPosition>
                </PopUp>
              </Form>
            );
          }}
        </Formik>
      </motion.section>
    </>
  );
};

export default CustomerForm;
