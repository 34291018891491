//import { getEnvs } from "./../../utils/getEnvs";

import { cartDB, genericMerchantDB } from "./../pouch/DBConn";

export const pullAllFromPouch = async (name: string) => {
  try {
    const res = await genericMerchantDB(name).allDocs({ include_docs: true });

    return res.rows
      .map((row: any) => (row.doc?.type !== "ddoc" ? row.doc : null))
      .filter(Boolean);
  } catch (err) {
    console.log("an error occured from this end man", err);
  }
};

export const addToCart = async (item: any) => {
  try {
    const res = await cartDB("carts").put(item);
    console.log(res, "this is the response man");
  } catch (err: any) {
    throw new Error(err);
  }
};
