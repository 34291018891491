import React from "react";

import GridList from "./grid-list/index";

import EmptyProductList from "./../../empty-list/index";

//interface IProductGridView {
//  data: Array<any>;
//}

const ProductGridView = ({
  data,
  isLoading,
  isError,
  error,
  handleAddToCart,
}: {
  [key: string]: any;
}) => {
  return (
    <div
      //variants={accordionVariant}
      className="row justify-content-start"
    >
      {isLoading ? (
        "pulling up"
      ) : error || isError ? (
        "Something went wrong."
      ) : !data?.length ? (
        <EmptyProductList />
      ) : (
        (data || []).map((product: any) => (
          <GridList
            {...{
              product,
              handleAddToCart,
            }}
            key={product?._id}
          />
        ))
      )}
    </div>
  );
};

export default ProductGridView;
