import { remoteDB } from "../DBConn";

import { IPincode } from "../../../models";

import { getEnvs } from "./../../../utils/getEnvs";
import { getSession } from "../../../utils/storage";

import { checkLocalInfo } from "../checkLocalInfo";

import { triggerToast } from "../../../toast";

const { user } = getEnvs();

export const shoppaPinCode = async (
  arg: IPincode,
  navigate: (arg: string, arg2: any) => void
) => {
  const userInfo: any = getSession(user);
  try {
    checkLocalInfo(userInfo.merchant_name, "Something went wrong!", navigate);

    await remoteDB(userInfo.merchant_name).put(arg);

    navigate("/terminal", { replace: true });

    triggerToast({
      type: "success",
      message: "Pincode successfully set! Keep it save!",
      duration: 6000,
    });
  } catch (err) {
    throw Error("Something went wrong. Try Again");
  }
};

export const shoppaGetPincode = async (data: any, navigate: any, cb: any) => {
  try {
    await remoteDB(data?.merchant_name).get("_local/" + data?.userName);
    cb();
    navigate("/terminal", { replace: true });
  } catch (err) {
    cb();
    navigate("/reset-code", { replace: true });
  }
};
