import React from "react";

import CartSummary from "../../../resuable-ui/cart-summary";

import styles from "./index.module.scss";

interface ISummary {
  data: Array<any>;
}

const Summary = ({ data }: ISummary) => {
  console.log(data, "this is the data man");
  return (
    <>
      <div className={styles.summary}>
        <span>Summary</span>
      </div>
      <section className={`${styles.wrapper}`}>
        {data.map((item) => (
          <CartSummary
            {...{
              name: item.name,
              amount: item.amount,
              quantity: item.quantity,
              id: item.id,
            }}
          />
        ))}
      </section>
    </>
  );
};

export default Summary;
