import React from "react";

import styles from "./index.module.scss";

import { formatAmount } from "../../../../../../utils/funcs";
import { DEFAULT_IMAGE_BASE64 } from "../../../../../../utils/msc";

const ProductListItem = ({ product, handleAddToCart }: any) => {
  //const handleAddToCart = (item: any) => {
  //  console.log(item, "man");
  //};
  return (
    <section
      className={`${styles.wrapper} col-12 my-3`}
      onClick={() => handleAddToCart(product)}
    >
      <div
        className={`${styles.innerWrapper} p-2 d-flex align-items-center justify-content-between`}
      >
        <div className={`${styles.imgBg} p-2`}>
          <img
            alt={product?.name}
            src={product?.image || DEFAULT_IMAGE_BASE64}
            height="55px"
            width="75px"
            className="img-fluid"
          />
        </div>

        <div className={`${styles.flex1} mx-3`}>
          <aside className={styles.title}>
            {product?.name},{product?.description}
          </aside>
        </div>

        <div
          className={`${styles.flex2} d-flex align-items-center justify-content-center`}
        >
          <aside className={styles.price}>
            &#8358; {formatAmount(product?.price ?? 0)}
          </aside>
        </div>
      </div>
    </section>
  );
};

export default ProductListItem;
