import React from "react";

import styles from "./index.module.scss";

import Button from "../../../../resuable-ui/button";

import RecordModalWrapper from "../../../../resuable-ui/generic-modal-wrapper";

interface IDeleteContent {
  item: {
    name: string;
    id: number;
  };
  setShow: (arg: boolean) => void;
}

export const DeleteModalContent = ({
  item: { name },
  setShow,
}: IDeleteContent) => {
  const renderTitle = () => <span className={styles.title}>Remove Item?</span>;

  return (
    <RecordModalWrapper title={renderTitle}>
      <div className={`${styles.itemName} w-100 text-center my-5`}>{name}</div>

      <div className="w-100 text-center my-5">
        <Button
          type="button"
          disabled={false}
          isBordered={true}
          styles="py-4 mx-3"
          style={{ width: "35%" }}
          onClick={() => setShow(false)}
        >
          No
        </Button>
        <Button
          type="button"
          disabled={false}
          style={{ width: "35%" }}
          styles="py-4"
        >
          Yes
        </Button>
      </div>
    </RecordModalWrapper>
  );
};
