import React from "react";

import styles from "./index.module.scss";

const Triangle = () => {
  return (
    <section className={`${styles.wrapper}`}>
      <div className={styles.innerWrapper}></div>
    </section>
  );
};

export default Triangle;
