import React from "react";

const ParkIconSvg = (props: any) => {
  return (
    <svg
      {...props}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.625C0 1.92881 0.276562 1.26113 0.768845 0.768845C1.26113 0.276562 1.92881 0 2.625 0H11.375C12.0712 0 12.7389 0.276562 13.2312 0.768845C13.7234 1.26113 14 1.92881 14 2.625V16.625C14 16.7782 13.9597 16.9288 13.8832 17.0615C13.8067 17.1943 13.6967 17.3047 13.5641 17.3815C13.4316 17.4584 13.2812 17.4992 13.128 17.4997C12.9747 17.5002 12.8241 17.4605 12.691 17.3845L10.0625 15.883L8.302 16.8892C7.90546 17.1158 7.45668 17.2349 7 17.2349C6.54332 17.2349 6.09454 17.1158 5.698 16.8892L3.9375 15.8821L1.309 17.3836C1.17601 17.4596 1.02542 17.4993 0.872262 17.4988C0.719108 17.4984 0.568763 17.4577 0.436253 17.3809C0.303744 17.3041 0.193712 17.1939 0.117156 17.0612C0.0406006 16.9286 0.000202473 16.7782 0 16.625V2.625ZM5.50638 3.75638C5.58709 3.6728 5.68364 3.60614 5.7904 3.56029C5.89715 3.51443 6.01197 3.49029 6.12815 3.48928C6.24433 3.48827 6.35955 3.51041 6.46708 3.55441C6.57462 3.5984 6.67231 3.66337 6.75447 3.74553C6.83663 3.82769 6.9016 3.92538 6.94559 4.03292C6.98959 4.14045 7.01173 4.25567 7.01072 4.37185C7.00971 4.48803 6.98557 4.60285 6.93971 4.7096C6.89386 4.81636 6.8272 4.91291 6.74362 4.99362L5.61225 6.125H7C7.92826 6.125 8.8185 6.49375 9.47487 7.15013C10.1313 7.8065 10.5 8.69674 10.5 9.625V10.5C10.5 10.7321 10.4078 10.9546 10.2437 11.1187C10.0796 11.2828 9.85706 11.375 9.625 11.375C9.39294 11.375 9.17038 11.2828 9.00628 11.1187C8.84219 10.9546 8.75 10.7321 8.75 10.5V9.625C8.75 9.16087 8.56563 8.71575 8.23744 8.38756C7.90925 8.05937 7.46413 7.875 7 7.875H5.61225L6.74362 9.00638C6.90301 9.1714 6.99121 9.39243 6.98921 9.62185C6.98722 9.85127 6.8952 10.0707 6.73297 10.233C6.57073 10.3952 6.35127 10.4872 6.12185 10.4892C5.89243 10.4912 5.6714 10.403 5.50638 10.2436L2.88138 7.61862C2.71734 7.45454 2.62519 7.23202 2.62519 7C2.62519 6.76798 2.71734 6.54546 2.88138 6.38138L5.50638 3.75638Z"
        fill="#7F5297"
      />
    </svg>
  );
};

export default ParkIconSvg;
