import React from "react";
import { sideMenuData } from "../../data/sideMenu";
import { ISideMenu } from "../../models";

import CloseIconSvg from "../svgs/CloseIconSvg";

import styles from "./index.module.scss";

import { motion, AnimatePresence } from "framer-motion";

import {
  sideMenuVariant,
  sMenuChildrenVariant,
} from "./../../utils/anims/index";

const SideMenu = ({ navState, setNavState }: ISideMenu) => {
  return (
    <>
      <AnimatePresence>
        {navState && (
          <motion.section
            variants={sideMenuVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={`${styles.wrapper} position-fixed text-center`}
          >
            <section className="text-center d-flex justify-content-center w-100">
              <div
                className={`${styles.close} my-5 py-2`}
                onClick={() => setNavState(false)}
              >
                <CloseIconSvg width="20" height="25" viewBox="0 0 25 25" />
              </div>
            </section>

            <motion.section>
              {sideMenuData.map(({ id, name, component: Component }) => {
                return (
                  <motion.div
                    variants={sMenuChildrenVariant}
                    key={id}
                    className={`p-4 ${id === 1 && styles.bgHighlight} ${
                      styles.cursor
                    }`}
                  >
                    <Component />
                    <aside className={`${styles.text} mt-2`}>{name}</aside>
                  </motion.div>
                );
              })}
            </motion.section>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default React.memo(SideMenu);
