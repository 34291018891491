import React from "react";
import { SalesTerminal } from "../components/sales-terminal";

import NavLayout from "./../components/layouts/NavLayout";

function SaleTerminal() {
  return (
    <section className="position-relative">
      <NavLayout {...{ showBar: true }}>
        <SalesTerminal />
      </NavLayout>
    </section>
  );
}

export default SaleTerminal;
