import React from "react";

import styles from "./index.module.scss";

import CatergoriesBar from "./categories";
import ProductGridView from "./product-list/grid";
import ProductNav from "./product-nav";
import ProductListView from "./product-list/list/index";

import { AnimatePresence } from "framer-motion";

import { useGet } from "./../../../integrations/react_query/queries";
//import { useAddToCart } from "../../../integrations/react_query/mutations";

import { EQuerykeys } from "../../../models";

import { handleProductReady } from "../../../store/slices/productSlice";

import { useAppDispatch } from "../../../store/storeConfig";
import ProductListWrapper from "./product-list-wrapper";
//import { useKDB } from "../../../hooks/useKDB";
//import { genId } from "./../../../utils/genId";

let timeout: any = null;
let count = 0;

export const Products = () => {
  let filteredData = "";

  const dispatch = useAppDispatch();

  const [toggleView, setToggleView] = React.useState(true);

  const { data, isLoading, error, isError, refetch }: any = useGet(
    [EQuerykeys.product],
    "products"
  );

  filteredData = data;

  const [category, setCategory] = React.useState<null | string>(null);

  if (category) {
    if (category === "all") {
      filteredData = data;
    } else {
      filteredData = data.filter((item: any) => {
        return item.category === category;
      });
    }
  }

  const [value, setValue] = React.useState("");

  const kdbRef = React.useRef(null);

  //useKDB({ setValue, ref: kdbRef });

  const reg = /^\d+$/;

  if (value && !reg.test(value)) {
    filteredData = data.filter((item: any) =>
      item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
  }

  if (reg.test(value) && value) {
    filteredData = data.filter((item: any) => "" + item.sku === value);
  }

  //const handleChange = (e: any) => {
  //  setCategory("");
  //  setValue(e.target.value);
  //};

  React.useEffect(() => {
    timeout = setTimeout(() => {
      count++;
      if (count > 10) clearTimeout(timeout);
      if (!data || !data?.length) {
        console.log(count, "number of refetching");
        refetch();
      } else {
        clearTimeout(timeout);
        dispatch(handleProductReady(true));
      }
    }, 2000);

    return () => clearTimeout(timeout);
  });

  console.log(data, isLoading, error, isError, "this is it man");

  //const { mutate } = useAddToCart();

  //const handleAddToCart = (item: any) => {
  //  console.log(item);
  //  const { name, price, location, branch, _id: prevId } = item;
  //  const newItem = {
  //    name,
  //    price,
  //    vat: 5,
  //    cashier: "newcshier",
  //    location,
  //    branch,
  //    _id: genId({ name: "cart", time: prevId }),
  //  };
  //  mutate(newItem);
  //};

  return (
    <>
      {/*<input type="text" ref={kdbRef} value={value} />*/}
      <section
        className={`${styles.wrapper} position-relative col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7`}
      >
        <section className={`${styles.title} mb-4`}>
          <span>Scan or Search Product</span>
        </section>

        <ProductNav
          {...{
            toggle: toggleView,
            setToggle: setToggleView,
            //onChange: handleChange,
            onChange: () => console.log("hi"),
            value,
            setValue,
            ref: (r: any) => (kdbRef.current = r),
          }}
        />

        <AnimatePresence>
          <ProductListWrapper>
            {toggleView ? (
              <ProductGridView
                {...{
                  isLoading,
                  error,
                  isError,
                  data: filteredData,
                  //handleAddToCart,
                }}
              />
            ) : (
              <ProductListView
                {...{
                  isLoading,
                  error,
                  isError,
                  data: filteredData,
                  //handleAddToCart,
                }}
              />
            )}
          </ProductListWrapper>
        </AnimatePresence>

        <CatergoriesBar {...{ category, setCategory }} />
      </section>
    </>
  );
};

export default Products;
